<template>
  <div class="relative flex flex-col width-full">
    <div class="my-2 mx-0">
      <p
        v-if="label"
        class="m-0 text-[#2a3443] antialiased text-sm dark:text-[#F7F9F9]"
      >
        {{ label }}
      </p>
    </div>

    <input
      ref="refInput"
      :name="name"
      :placeholder="placeholder"
      type="text"
      autocomplete="off"
      :value="modelValue"
      class="border border-b-2 py-3 px-4 h-10 border-gray-300 shadow-none w-full rounded-sm text-sm text-black focus:outline-none focus:ring focus:ring-blue-500 focus:border-transparent black:text-white"
      :class="{ '!border-red-600': error }"
      @input="updateInput"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },

  error: {
    type: Boolean,
    defaultValue: false,
  },

  label: {
    type: String,
    required: false,
  },

  name: {
    type: String,
    required: true,
  },

  placeholder: {
    type: String,
    required: false,
  },

  focusOnInit: {
    type: Boolean,
    defaultValue: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const refInput = ref(null);

onMounted(() => {
  if (props.focusOnInit) {
    refInput.value.focus();
  }
});

const updateInput = (event) => {
  emit('update:modelValue', event.target.value);
};
</script>
