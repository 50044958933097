import dayjs from 'dayjs';

// By default we will use the NL locale.
import localeNL from 'dayjs/locale/nl';

import relativeTime from "dayjs/plugin/relativeTime";

export function dateFromNow(dateString) {
  return dayjs(dateString).locale(localeNL).fromNow();
};

export function publishedAt(dateString) {
  return dayjs(dateString).locale(localeNL).format("DD MMMM YYYY");
};

dayjs.locale(localeNL);
dayjs.extend(relativeTime);

export default dayjs;