import ApiClient from '@/api/ApiClient';
import ApiConfig from '@/api/config/ApiConfig';
import ChannelService, { ChannelApiClient } from '@/api/services/ChannelService';
import UserService, { UserApiClient } from '@/api/services/UserService';
import appConfig from './config/appConfig';
import AdminService, { AdminApiClient } from './services/AdminService';
import OrganisationService, { OrganisationApiClient } from './services/OrganisationService';
import ReplyService, { ReplyApiClient } from './services/ReplyService';
import PostService, { PostApiClient } from './services/PostService';
import SettingService, { SettingApiClient } from './services/SettingService';
import AdminMigrationService, { AdminMigrationApiClient } from './services/admin/AdminMigrationService';
import AdminEventService, { AdminEventsApiClient } from './services/admin/AdminEventService';

const { baseUrl } = appConfig;

// userApiConfig.accessToken = appConfig.accessToken;

const apiConfig = new ApiConfig();

const channelApiClient = new ChannelApiClient(new ApiClient(baseUrl, apiConfig));
export const channelService = new ChannelService(channelApiClient);

const userApiClient = new UserApiClient(new ApiClient(baseUrl, apiConfig));
export const userService = new UserService(userApiClient);

const adminApiClient = new AdminApiClient(new ApiClient(baseUrl, apiConfig));
export const adminService = new AdminService(adminApiClient);

const organisationApiClient = new OrganisationApiClient(new ApiClient(baseUrl, apiConfig));
export const organisationService = new OrganisationService(organisationApiClient);

const replyApiClient = new ReplyApiClient(new ApiClient(baseUrl, apiConfig));
export const replyService = new ReplyService(replyApiClient);

const postApiClient = new PostApiClient(new ApiClient(baseUrl, apiConfig));
export const postService = new PostService(postApiClient);

const settingApiClient = new SettingApiClient(new ApiClient(baseUrl, apiConfig));
export const settingService = new SettingService(settingApiClient);

const adminMigrationApiClient = new AdminMigrationApiClient(new ApiClient(baseUrl, apiConfig));
export const adminMigrationService = new AdminMigrationService(adminMigrationApiClient);

const adminEventsApiClient = new AdminEventsApiClient(new ApiClient(baseUrl, apiConfig));
export const adminEventService = new AdminEventService(adminEventsApiClient);