import appConfig from '@/api/config/appConfig';
import type { IApiClient } from '@/api/api.types';
import type { Channel } from '@/types/channel.types';
import type { Participant } from '@/types/participant.types';

export interface IChannelApiClient {
  getChannelParticipants(id: Channel['id']): Promise<Participant[]|undefined>;
  deleteChannel(id: Channel['id']): Promise<boolean>;
}

export interface getChannelReponse {
  participants: Participant[];
};

export class ChannelApiClient implements IChannelApiClient {
  apiBase: string;
  channelApiClient: IApiClient;

  constructor(channelApiClient: IApiClient) {
    this.apiBase = appConfig.apiBase;
    this.channelApiClient = channelApiClient;
  }

  async getChannelParticipants(id: Channel['id']): Promise<Participant[]|undefined> {
    try {
      const response = await this.channelApiClient.get<getChannelReponse>(
        `${this.apiBase}/channels/${id}/participants`
      );

      return response.participants;
    } catch (exception) {
      console.error(exception);
      return [];
    }
  }

  async deleteChannel(id: Channel['id']): Promise<boolean> {
    try {
      const response = await this.channelApiClient.delete<getChannelReponse>(
        `${this.apiBase}/channels/${id}`
      );

      return response ? true : false;
    } catch (exception) {
      console.error(exception);
      return false;
    }
  }
}

export default class ChannelService {
  channelApiClient: IChannelApiClient;

  constructor(channelApiClient: IChannelApiClient) {
    this.channelApiClient = channelApiClient;
  }

  async getChannelParticipants(channelId: Channel['id']): Promise<Participant[]|undefined> {
    return this.channelApiClient.getChannelParticipants(channelId);
  };

  async deleteChannel(channelId: Channel['id']): Promise<boolean> {
    return this.channelApiClient.deleteChannel(channelId);
  };
}