import { defineStore } from 'pinia';

const useConfigStore = defineStore('config', {
  state: () => ({
    pages: {
      admin: true,
      channels: true,
      dashboard: true,
      discover: false,
      maps: true,
      notifications: true,
      pages: false,
      people: false,
      privacy: false,
      profile: true,
      timeline: false,
    },
    permissions: [],
    sidebar: {
      options: false,
      sort: false,
    },
    events: {
      changeDeprecatedPassword: true,
    },
  }),

  actions: {
    showPages(state: boolean) {
      this.pages.admin = state;
      this.pages.channels = state;
      this.pages.dashboard = state;
      this.pages.discover = state;
      this.pages.maps = state;
      this.pages.notifications = state;
      this.pages.pages = state;
      this.pages.people = state;
      this.pages.privacy = state;
      this.pages.timeline = state;
    },
    
    showNavigationItems(state: boolean) {
      this.pages.notifications = state;
    },
  }
});

export default useConfigStore;