import useChannelStore from '@/store/hooks/useChannelStore';
import axios from 'axios';
import type {
  Channel,
  CreateChannel,
  MergeChannelsProps,
  UpdateChannel,
} from '@/types/channel.types';
import { channelService } from '@/api';

const endpoint = '/api/v1/channels';

export default function useChannels() {
  const addChannels = (channels: Channel[]) => {
    const channelStore = useChannelStore();

    channels.forEach((channel) => {
      channelStore.create(channel);
    });
  };

  const activateChannel = async (channelId: Channel['id']) => {
    const channelStore = useChannelStore();

    try {
      const channel = getChannelById(channelId);

      if (!channel) {
        return {
          state: 'error',
          data: {},
        };
      }

      const postEndpoint = `${endpoint}/${channel.id}/activate`;
      const response = await axios.post(postEndpoint);

      channelStore.update(response.data.data);

      return {
        state: 'success',
        data: response.data.data,
      };
    } catch (error: any) {
      return {
        state: 'error',
        data: error,
      };
    }
  };

  const archiveChannel = async (channelId: Channel['id']) => {
    const channelStore = useChannelStore();

    try {
      const channel = getChannelById(channelId);

      if (!channel) {
        return {
          state: 'error',
          data: {},
        };
      }

      const deleteEndpoint = `${endpoint}/${channel.id}`;
      const response = await axios.delete(deleteEndpoint);

      channelStore.update(response.data.data);

      return {
        state: 'success',
        data: response.data.data,
      };
    } catch (error: any) {
      return {
        state: 'error',
        data: error,
      };
    }
  };

  const getChannels = () => {
    const channelStore = useChannelStore();

    return channelStore.getChannels;
  };

  const getOnlyPostableChannels = () => {
    const channels = getChannels();

    return channels.filter((channel: Channel) => channel.permissions.canPost);
  };

  const addChannelsFromPost = (channelIds: number[]): Promise<unknown> => {
    const channelStore = useChannelStore();

    const validate = async (channelId: Channel['id']) => {
      let channel = getChannelById(channelId) as Channel;

      if (!channel) {
        const response = await fetchChannelById(channelId);

        if (!response) {
          return Promise.reject();
        }

        channel = response;
      }

      channelStore.create(channel);

      return Promise.resolve();
    };

    const promises: any[] = [];

    channelIds.map((channelId) => {
      promises.push(validate(channelId));
    });

    return Promise.all(promises).catch((error) =>
      console.log(`Error in executing ${error}`),
    );
  };

  const channelCanPostAsBoard = (channelIds: number[]) => {
    const channelStore = useChannelStore();

    return channelStore.canPostAsBoard(channelIds);
  };

  const deleteChannel = async (id: Channel['id']): Promise<any> => {
    const channelStore = useChannelStore();

    const result = await channelService.deleteChannel(id);

    if (result) {
      channelStore.delete(id);
    }
  };

  const fetchChannelById = (channelId: number): Promise<Channel | null> => {
    return new Promise(async (resolve) => {
      try {
        const response = await axios.get(`${endpoint}/${channelId}`);
        const { data } = response.data;

        if (data.length) {
          addChannels(data);
        }

        resolve(data);
      } catch (e) {
        resolve(null);
      }
    });
  };

  const fetchChannels = async () => {
    const response = await axios.get(endpoint);
    const { data } = response.data;

    if (data.length) {
      addChannels(data);
    }
  };

  const getChannelById = (id: string): Channel | undefined => {
    const channelStore = useChannelStore();

    return channelStore.getById(parseInt(id));
  };

  const createChannel = async (data: CreateChannel) => {
    const channelStore = useChannelStore();
    const errors = [];

    try {
      const response = await axios.post(endpoint, data);

      channelStore.create(response.data.data);

      return {
        state: 'success',
        data: response.data.data,
      };
    } catch (error: any) {
      if (error.response.status === 422) {
        for (const key in error.response.data.errors) {
          errors.push(error.response.data.errors[key][0]);
        }

        return {
          state: 'error',
          data: errors,
        };
      }
    }
  };

  const mergeChannels = async (state: MergeChannelsProps) => {
    const channelStore = useChannelStore();

    const data = {
      first_channel_id: state.firstChannelId,
      second_channel_id: state.secondChannelId,
      name: state.name,
      description: state.description,
      private: state.private,
      posted_as_board: state.posted_as_board,
    };

    const mergeChannelEndpoint = `${endpoint}/merge`;

    const response = await axios.post(mergeChannelEndpoint, data);

    const newChannel = response.data.data;

    channelStore.delete(state.firstChannelId);
    channelStore.delete(state.secondChannelId);
    channelStore.create(newChannel);

    return newChannel;
  };

  /**
   * Check if the property exists, then patch the post.
   */
  const updateChannel = async (
    channelId: Channel['id'],
    state: UpdateChannel,
  ) => {
    const channelStore = useChannelStore();
    const errors = [];

    const channel = getChannelById(channelId);

    if (!channel) {
      return {
        state: 'error',
        data: {},
      };
    }

    try {
      const formValues = { ...channel, ...state };
      const updateEndpoint = `${endpoint}/${channel.id}`;
      const response = await axios.put(updateEndpoint, formValues);

      channelStore.update(response.data.data);

      return {
        state: 'success',
        data: response.data.data,
      };
    } catch (error: any) {
      if (error.response.status === 422) {
        for (const key in error.response.data.errors) {
          errors.push(error.response.data.errors[key][0]);
        }

        return {
          state: 'error',
          data: errors,
        };
      }
    }
  };

  return {
    addChannelsFromPost,
    activateChannel,
    archiveChannel,
    channelCanPostAsBoard,
    createChannel,
    deleteChannel,
    fetchChannelById,
    fetchChannels,
    getChannelById,
    getChannels,
    getOnlyPostableChannels,
    mergeChannels,
    updateChannel,
  };
}
