<template>
  <slot />
</template>

<script setup>
  import useConfig from '@/composables/useConfig';
  import useUsers from '@/composables/useUsers';
  import useModal from '@/composables/useModal';
  import ChangeDeprecatedPasswordModal from '@/components/modal/ChangeDeprecatedPasswordModal.vue';

  /**
   * This is a temporary solution to force users to change their password.
   */

  const { changeDeprecatedPassword } = useConfig();
  const { hasDeprecatedPassword } = useUsers();
  const {setModalTitle, setModalComponent, openModal} = useModal();

  if (changeDeprecatedPassword() && hasDeprecatedPassword()) {
    setModalTitle('Vernieuw uw wachtwoord');
    setModalComponent(ChangeDeprecatedPasswordModal);
    openModal();
  }
</script>
