import appConfig from '@/api/config/appConfig';
import type { IApiClient } from '@/api/api.types';
import type { Settings } from '@/types/settings.types';

export interface ISettingApiClient {
  getSettings(): Promise<Settings|undefined>;
}

type GetSettingResponse = {
  data: Settings;
}

export class SettingApiClient implements ISettingApiClient {
  apiBase: string;
  settingApiClient: IApiClient;

  constructor(settingApiClient: IApiClient) {
    this.apiBase = appConfig.apiBase;
    this.settingApiClient = settingApiClient;
  }

  async getSettings(): Promise<Settings | undefined> {
    try {
      const response = await this.settingApiClient.get<GetSettingResponse>(
        `${this.apiBase}/settings`
      );

      return response.data ? response.data : undefined;
    } catch (exception) {
      console.error(exception);
    }
  }
}

export default class SettingService {
  settingApiClient: ISettingApiClient;

  constructor(settingApiClient: ISettingApiClient) {
    this.settingApiClient = settingApiClient;
  }

  async getSettings(): Promise<unknown> {
    return this.settingApiClient.getSettings();
  };
}