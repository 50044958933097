<template>
  <ButtonBase
    :styleClass="variantClasses"
    styleClass="text-gray-800 bg-white hover:bg-gray-800 hover:text-white"
    :disabled="disabled"
    :label="label"
    v-bind="$props"
  ></ButtonBase>
</template>

<script setup>
import { computed } from 'vue';
import ButtonBase from '@/components/button/ButtonBase.vue';
import { VARIANT_NORMAL, VARIANT_DANGER, VARIANT_SECONDARY, VARIANT_PRIMARY } from '@/types/button.js';

const props = defineProps({
  disabled: {
    type: Boolean,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
  variant: {
    default: VARIANT_NORMAL,
    type: String,
  }
});

const variantClasses = computed(() => {
  if (props.variant === VARIANT_DANGER) {
    return 'button__danger';
  }

  if (props.variant === VARIANT_SECONDARY) {
    return '';
  }

  if (props.variant === VARIANT_PRIMARY) {
    return 'button__primary';
  }

  return 'button__normal';
});
</script>

<style lang="scss" scoped>
  .button {
    &__danger {
      @apply bg-red-500 border-red-500 hover:bg-red-600;
    }

    &__primary {
      @apply bg-white text-primary border-primary hover:bg-primary-light hover:border-primary-light;
    }

    &__secondary {
      @apply bg-white text-secondary border-secondary hover:bg-secondary-light hover:border-secondary-light;
    }

    &__normal {
      @apply bg-white text-grey border-grey hover:bg-grey-light;
    }
  }
</style>