import { markRaw, reactive, readonly } from 'vue';

type InitialState = {
  buttons: any[];
  imgSrc: string | null;
  modalComponent: any;
  options: any;
  show: boolean;
  title: string | null;
  modalClass: string;
};

const initialState = {
  buttons: [],
  imgSrc: null,
  modalComponent: null,
  options: {},
  show: false,
  title: null,
  modalClass: 'w-96',
};

type InitialStateType = keyof typeof initialState;

const data = reactive<InitialState>(initialState);

let _cb = () => {};

export default function useModal() {
  const closeModal = (cbValues) => {
    data.show = false;

    _cb(cbValues);
  };

  const openModal = (cb: () => void = () => {}) => {
    if (cb && typeof cb === 'function') {
      _cb = cb;
    }

    data.show = true;
  };

  const setModalComponent = (component: InitialState['modalComponent']) =>
    (data.modalComponent = markRaw(component));
  const setModalOptions = (options?: [InitialState['options']]) => {
    data.options = options || {};
  };
  const setModalTitle = (title: InitialState['title']) => (data.title = title);
  const setImagSrc = (imgSrc: InitialState['imgSrc']) => (data.imgSrc = imgSrc);
  const setModalClass = (classString: string) =>
    (data.modalClass = classString);

  const resetModalState = () => {
    _cb = () => {};

    Object.keys(initialState).forEach((key) => {
      /* @ts-ignore */
      data[key as InitialStateType] = initialState[key as InitialStateType];
    });
  };

  return {
    modalState: readonly(data),
    openModal,
    closeModal,
    setModalComponent,
    setModalOptions,
    setModalTitle,
    setImagSrc,
    setModalClass,
    resetModalState,
  };
}
