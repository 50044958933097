import {createRouter, createWebHistory} from 'vue-router';
import routes from '@/router/routes.js';
import validateRoles from '@/router/guards/validateRoles';

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
  routes,
});

router.beforeEach(validateRoles);

export default router;