import type { Organisation } from '@/types/organisation.types';
import { defineStore } from 'pinia';

type OrganisationStore = {
  organisation: Organisation[];
}

export default defineStore('organisation', {
  state: (): OrganisationStore => ({
    organisation: [],
  }),

  actions: {
    create(organisation: Organisation) {
      this.organisation = [organisation];
    }
  }
});