export default {
  actions: {
    cancel: 'Annuleren',
    close: 'Sluiten',
    confirm: 'Bevestigen',
    create: 'Aanmaken',
    submit: 'Opslaan',
  },
  app: {
    name: 'Spreeuwzwerm',
    version: 'Versie {version}',
  },
  general: {
    cancel: 'Annuleren',
    close: 'Sluiten',
    save: 'Opslaan',
  },
  board: {
    name: {
      fullName: 'Bestuur Spreeuwzwerm',
      initials: 'BSP',
    },
    post: {
      title: 'Bestuur Spreeuwzwerm',
    },
  },
  channel: {
    createPost: {
      validation: {
        channelId: {
          required: 'Defineer een kanaal',
        },
        content: {
          required: 'Het bericht is niet optioneel',
        },
      },
      placeholder: 'Schrijf een nieuw bericht',
      tabs: {
        message: 'Bericht',
        media: "Foto's & video",
        link: 'Link',
        poll: 'Peiling',
      },
    },
    notFound: {
      description:
        'Oh nee, we hebben het kanaal waar je naar zocht niet kunnen vinden. Het kan zijn het verkeerde adres is, dat het kanaal is verwijderd of is afgeschermd. Mocht dit echter niet kloppen, neem dan contact op met het bestuur.',
      overview: 'Ga naar het overzicht',
      title: 'Kanaal niet gevonden',
    },
  },
  channels: {
    index: {
      title: 'Kanalen',
      description:
        'Dit zijn alle kanalen die beschikbaar zijn. Klik op een kanaal om de berichten te bekijken.',
    },
    description: 'Omschrijving',
    header: {
      name: 'Kanalen ontdekken',
      slug: 'kanalen-ontdekken',
    },
    navigation: {
      messages: 'Tijdslijn',
      settings: 'Instellingen',
    },
    forms: {
      create: {
        field: {
          name: {
            label: 'Naam voor kanaal',
            placeholder: 'Houd het kort en beschrijvend',
          },
          description: {
            label: 'Omschrijving',
            placeholder: 'Geef een beschrijving voor dit kanaal',
          },
          posted_as_board: {
            label: 'Onder naam van het bestuur',
          },
          private: {
            label: 'Privé kanaal',
          },
        },
      },
      update: {
        field: {
          name: {
            label: 'Naam voor kanaal',
            placeholder: 'Houd het kort en beschrijvend',
          },
          description: {
            label: 'Omschrijving',
            placeholder: 'Geef een beschrijving voor dit kanaal',
          },
          posted_as_board: {
            label: 'Onder naam van het bestuur',
          },
          private: {
            label: 'Privé kanaal',
          },
        },
      },
    },
    page: {
      settings: {
        base: {
          title: 'Kanaal instellingen',
        },
        activate: {
          actions: {
            activate: 'Activeren',
          },
          alreadyArchived: 'Dit kanaal is al gearchiveerd.',
          loading: 'Het kanaal wordt geactiveerd',
          title: 'Kanaal activeren',
        },
        archive: {
          actions: {
            archive: 'Archiveren',
          },
          description:
            'Een kanaal archiveren is alleen mogelijk wanneer er geen berichten in het kanaal staan, geplaatst door andere gebruikers.',
          noArchive: 'Het is niet mogelijk om dit kanaal te archiveren.',
          loading: 'Het kanaal wordt gearchiveerd',
          title: 'Kanaal archiveren',
        },
      },
    },
    createdBy: {
      label: 'Kanaal aangemaakt door',
    },
  },
  map: {
    index: {
      title: 'Plattegrond Loodsmansduin',
      description:
        'Dit is de plattegrond van de Loodsmansduin. Alle staanplaatsen van de huisjes en kampeerplekken staan hierop vermeld. De Spreeuwzwerm bevat tevens informatie over alle leden van de vereniging.',
      cta: 'Klik op een huisnummertje en bekijk of de informatie die hierbij wordt gegeven.',
    },
    overview: {
      noUsers: 'Deze chalet bevat geen actieve leden.',
    },
  },
  menu: {
    admin: 'Admin',
    channels: 'Kanalen',
    map: 'Plattegrond',
    messages: 'Tijdslijn',
    settings: 'Mijn gegevens',
  },
  navigation: {
    goBack: 'Ga terug',
    logout: 'Uitloggen',
    panelClose: 'Navigatiepaneel sluiten',
    poweredBy: 'Geleverd door MK Webdesign',
    privacy: 'Privacybeleid',
    requestHelp: 'Hulp krijgen',
    userGuideReset: 'Gebruikers-gids resetten',
    userSettings: 'Gebruikersinstellingen',
  },
  noResults: {
    foundNothing: 'Niets gevonden',
    endOfPosts: 'Je hebt het einde bereikt.',
  },
  post: {
    actions: {
      delete: 'Verwijderen',
      edit: 'Bewerken',
      multipleChannels: 'Bericht op meerdere kanalen plaatsen',
      saveDraft: 'Concept aan',
      show: 'Bericht bekijken',
    },
    content: {
      edited: 'Bewerkt',
    },
    comment: {
      label: 'Reageer',
      submit: 'Reactie plaatsen',
    },
    delete: {
      title: 'Bericht verwijderen',
      description: 'Weet u zeker dat het bericht moet worden verwijderd?',
      loading: 'Het bericht wordt verwijderd',
    },
    editor: {
      create: {
        title: 'Schrijf een nieuw bericht',
      },
      edit: {
        title: 'Pas het bericht aan',
      },
    },
    form: {
      field: {
        channel: {
          label: 'Kies een kanaal',
          placeholder: 'Kies een kanaal',
        },
        postAsBoard: {
          label: 'Plaats bericht als bestuur',
        },
      },
      submit: {
        error: 'Er is iets mis gegaan tijdens het opslaan van het bericht.',
        success: 'Het bericht is succesvol opgeslagen.',
      },
    },
  },
  permissions: {
    title: 'Oeps, er is iets misgegaan',
    description: 'U heeft niet de juiste rechten om deze pagina te bekijken.',
    contact: 'Neem contact op met het bestuur.',
  },
  replies: 'reactie|reacties',
  reply: {
    actions: {
      delete: 'Verwijderen',
      edit: 'Bewerken',
    },
    content: {
      edited: 'Bewerkt',
    },
    delete: {
      title: 'Bericht verwijderen',
      description: 'Weet u zeker dat het bericht moet worden verwijderd?',
      loading: 'Het bericht wordt verwijderd',
    },
    showAll: 'Meer Reacties tonen',
  },
  table: {
    channels: {
      actions: {
        merge: 'Kanalen samenvoegen',
      },
      update: {
        modal: {
          title: 'Kanaal aanpassen',
        },
      },
    },
    actions: {
      activate: 'Activeren',
      deactivate: 'Inactief maken',
      duplicate: 'Dupliceren',
      edit: 'Aanpassen',
      merge: 'Samenvoegen',
    },
  },
  user: {
    account: {
      email: 'E-mail',
      label: 'Mijn inlog',
      password: 'Wachtwoord',
    },
    actions: {
      edit: 'Aanpassen',
    },
    chalet: {
      chalet: 'Chalet',
      label: 'Mijn Chalet',
      noResult: 'Er is geen chalet gevonden.',
    },
    information: {
      additional: {
        emergencyResponse: 'BHV',
      },
      modal: {
        personal: {
          address: {
            city: 'Plaats',
            country: 'Land',
            number: 'Nummer',
            postcode: 'Postcode',
            street: 'Straat',
            suffix: 'Toevoeging',
          },
          emergencyResponse: "Ik ben een BHV'er",
          extra: 'Extra',
          firstName: 'Voornaam',
          language: 'Taal',
          lastname: 'Achternaam',
          lastnamePrefix: 'Tussenvoegsel',
          loading: {
            title: 'Gegevens worden opgeslagen',
          },
          mobile: 'Mobiel',
          name: 'Naam',
          preferredLanguage: 'Voorkeurstaal',
          profession: 'Beroep',
          telephone: 'Telefoon',
          title: 'Persoonlijke gegevens aanpassen',
          notification: {
            error: 'Er is iets misgegaan tijdens het opslaan van de gegevens.',
            success: 'De gegevens zijn succesvol opgeslagen.',
            noChanges: 'Er zijn geen wijzigingen doorgevoerd.',
          },
        },
        chalet: {
          chalet: 'Chalet',
          noChalet: 'Geen chalet',
          loading: {
            title: 'Chalet wordt aangepast',
          },
          title: 'Chalet aanpassen',
          notification: {
            error: 'Er is iets misgegaan tijdens het opslaan van de gegevens.',
            success: 'De chalet is succesvol opgeslagen.',
            noChanges: 'Er zijn geen wijzigingen doorgevoerd.',
          },
        },
      },
      label: 'Mijn gegevens',
      name: 'Naam',
      address: 'Adres',
      telephone: 'Telefoon',
      mobile: 'Mobiel',
      language: 'Taal',
      profession: 'Beroep',
    },
    invite: {
      modal: {
        description:
          'Dit is de link naar het platform voor de gebruiker. Deze kan los gecommuniceerd worden. Let op! Deze link is alleen te gebruiken door de nieuwe gebruiker en zal niet meer actief zijn als gebruiker geactiveerd is.',
      },
    },
    membership: {
      label: 'Mijn lidmaatschap',
      memberSince: 'Lid sinds',
      role: 'Verenigingsrol',
    },
    photo: {
      label: 'Mijn Profielfoto',
    },
    roles: {
      admin: 'Beheerder',
      board: 'Bestuur',
      member: 'Lid',
      user: 'Lid',
    },
    status: {
      activate_token_requested: 'Activatie token aangevraagd',
      active: 'Actief',
      deprecated_password_active: 'Oud wachtwoord actief',
      email_address_not_verified: 'E-mailadres niet geverifieerd',
      inactive: 'Inactief',
      protected: 'Beveiligd',
      reset_password_token_requested: 'Wachtwoord reset aangevraagd',
      undefined: 'Onbekend',
    },
  },
  admin: {
    users: {
      modal: {
        createUser: {
          title: 'Een nieuwe gebruiker aanmaken',
          submit: {
            error: 'Er is iets misgegaan tijdens het aanmaken van het account.',
            success: 'Het account is succesvol aangemaakt.',
          },
        },
      },
      profile: {
        modal: {
          activateAccount: {
            description:
              'Wilt u dat de gebruiker wordt geactiveerd? Zo ja, wordt er een e-mail verstuurd met daarin een activatie link. De gebruiker kan alleen zelf zijn account activeren.',
            loading: 'De gebruiker wordt geactiveerd!',
            title: 'Activeer account: {name}',
            submit: {
              error:
                'Er is iets misgegaan tijdens het activeren van het account.',
              success: 'De gebruiker is uitgenodigd.',
            },
          },
          deactivateAccount: {
            description:
              'Weet u zeker dat het account moet worden gedeactiveerd?',
            loading: 'De gebruiker wordt gedeactiveerd!',
            title: 'Deactiveer account: {name}',
          },
          updateAddress: {
            loading: 'Het adres wordt aangepast!',
            form: {
              street: 'Straat',
              number: 'Nummer',
              suffix: 'Toevoeging',
              postcode: 'Postcode',
              city: 'Plaats',
              country: 'Land',
            },
            title: 'Adres aanpassen',
          },
          updateChalet: {
            loading: 'Het chalet wordt aangepast!',
            form: {
              chalet: 'Chalet',
              noChalet: 'Geen chalet',
            },
            title: 'Chalet aanpassen',
          },
          updateRole: {
            loading: 'De rol wordt aangepast!',
            form: {
              role: 'Rol',
            },
            title: 'Rol aanpassen',
          },
          removeAccount: {
            description: 'Wilt u dat de gebruiker wilt verwijderen?',
            loading: 'De gebruiker wordt verwijderd!',
            title: 'Verwijder account: {name}',
            submit: {
              error:
                'Er is iets misgegaan tijdens het verwijderen van het account.',
              success: 'De gebruiker is verwijderd.',
            },
          },
          resetPassword: {
            title: 'Wachtwoord resetten',
          },
          retryResetPassword: {
            title: 'Wachtwoord nogmaals resetten',
          },
        },
        actions: {
          activateAccount: 'Activeer account',
          deactivateAccount: 'Account deactiveren',
          removeAccount: 'Verwijder account',
          resetPassword: 'Wachtwoord resetten',
          retryResetPassword: 'Wachtwoord nogmaals resetten',
          updateAddress: 'Adres aanpassen',
          updateChalet: 'Chalet aanpassen',
          updateRole: 'Rol aanpassen',
        },
        activateToken: {
          label: 'Activatie token',
          link: 'Link',
          sendAt: 'Vestuurd',
          token: 'Token',
        },
        items: {
          chalet: 'Chalet',
          email: 'E-mail',
          map: 'Plattegrond',
          position: 'Positie binnen de vereniging',
          state: 'Status',
          stateEvents: 'Status bijzonderheden',
        },
        headers: {
          account: 'Account',
          additional: 'Extra informatie',
          address: 'Adres',
        },
        notification: {
          inactive:
            'De gebruiker is inactief. De gebruiker kan niet inloggen of zijn niet zichtbaar op de plattegrond. Eventuele reeds geplaatste berichten zijn wel zichtbaar.',
          userNotFound: 'De gebruiker is niet gevonden.',
          userRemoved: 'De gebruiker is verwijderd.',
        },
      },
    },
  },
};
