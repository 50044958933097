<template>
  <div class="relative flex flex-col width-full">
    <div class="my-2 mx-0">
      <p class="input__label">{{label}}</p>
    </div>

    <input
      :name="name"
      :placeholder="placeholder"
      type="password"
      autocomplete="off"
      :value="modelValue"
      @input="updateInput"
      style="width: 100%; height: 40px; border-radius: 4px; border: 1px solid rgb(186, 198, 208); box-sizing: border-box; box-shadow: none; font-size: 14px; padding: 10px 12px; font-weight: normal; font-style: normal; font-stretch: normal; line-height: 1.43; letter-spacing: 0.3px; color: rgb(0, 0, 0);"
      class="border border-b-2 border-gray-300 rounded-sm focus:outline-none focus:ring focus:ring-blue-500 focus:border-transparent"
      :class="{error: 'border-red-600'}"
    />
  </div>
</template>

<script setup>
  const props = defineProps({
    modelValue: {
      type: String,
      default: '',
    },

    error: {
      type: Boolean,
      defaultValue: false,
    },

    label: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
    },
  });

  const emit = defineEmits(['update:modelValue']);

  const updateInput = (event) => {
    emit('update:modelValue', event.target.value);
  };
</script>

<style lang="scss" scoped>
  .input {
    &__label {
      margin: 0px;
      color: rgb(42, 52, 67);
      -webkit-font-smoothing: antialiased;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 20px;
    }
  }
</style>