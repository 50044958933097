const roles = {
  NoUser: 0,
  User: 1,
  Board: 2,
  Admin: 3,
} as const;

export type TypeOfRoles = (typeof roles)[keyof typeof roles];

export const roleOptions = [
  {
    id: roles.NoUser,
    label: 'No User',
    name: 'no-user',
  },
  {
    id: roles.User,
    label: 'User',
    name: 'user',
  },
  {
    id: roles.Board,
    label: 'Board',
    name: 'board',
  },
  {
    id: roles.Admin,
    label: 'Admin',
    name: 'admin',
  },
];

export default roles;
