import {createI18n} from 'vue-i18n';
import nl from '@/i18n/locales/nl';
import en from '@/i18n/locales/en';

const messages = {
  en: en,
  nl: nl,
}

const LANGUAGE_NL = 'nl';
const LANGUAGE_EN = 'en';

export const languages = [LANGUAGE_EN, LANGUAGE_NL];

// Create VueI18n instance with options
const i18n = createI18n({
  legacy: false,
  locale: LANGUAGE_NL,
  messages,
});

export default i18n;