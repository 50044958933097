import axios from 'axios';
import useUserStore from '@/store/hooks/useUserStore';
import type { User } from '@/types/user.types';
import diff from 'deep-diff';
import deepen from '@/helpers/deepen';

const endpoint = '/api/v1/me';
const userPasswordEndpoint = '/api/v1/me/password';

type PostDeprecatedPassword = {
  newPassword: string;
  confirmPassword: string;
};

export default function useUsers() {
  const userStore = useUserStore();

  const isAuthor = (authorId: User['id']) => userStore.isAuthor(authorId);

  const getUser = () => userStore.getuser;

  const isAdmin = () => userStore.isAdmin;
  const isUser = () => userStore.isUser;
  const isBoard = () => userStore.isBoard;

  const getUserId = () => userStore.getuser.id;

  const updateUser = async (state: User) => {
    const currentUser = getUser();
    const params: Partial<User> = {};

    const differences = diff(currentUser, state);

    if (!differences) {
      return;
    }

    differences.forEach((difference) => {
      if (difference.kind !== 'E' || !difference.path) {
        return;
      }

      const key = difference.path.join('.');
      params[key] = difference.rhs;
    });

    // If no differences, return
    if (!Object.keys(params).length) {
      return;
    }

    // Format from object with dot notation to nested object.
    const formattedParams = deepen<Partial<User>>(params);

    const result = await axios
      .patch('/api/v1/me', formattedParams)
      .then((response) => {
        const user = userStore.set(response.data.data);

        return {
          state: 'success',
          data: user,
        };
      })
      .catch((err) => {
        return {
          state: 'error',
          data: err,
        };
      });

    return result;
  };

  const updateUserChalet = async (formChaletId: number | null) => {
    const currentUser = getUser();

    console.log('updateUserChalet');
    console.log('currentUser: ', currentUser);
    console.log('formChaletId: ', formChaletId);

    if (!currentUser.chaletIds.length && !formChaletId) {
      return {
        state: 'data-not-changed',
      };
    }

    if (formChaletId && currentUser.chaletIds.includes(formChaletId)) {
      return {
        state: 'data-not-changed',
      };
    }

    const params = {
      chaletIds: [formChaletId],
    };

    // now we send this to the API where the data is updated in the database
    // user->chalets->attach($chaletId) or user->chalets->detach($chaletId)
    const result = await axios
      .post('/api/v1/me/chalet', params)
      .then((response) => {
        // check if there is a response
        const user = userStore.set(response.data.data);

        return {
          state: 'success',
          data: user,
        };
      })
      .catch((err) => {
        return {
          state: 'error',
          data: err,
        };
      });

    return result;
  };

  const updateUserLogin = async (state: unknown) => {
    const currentUser = getUser();
    const params: Partial<User> = {};

    console.log('updateUserLogin');
    console.log('currentUser: ', currentUser);
    console.log('params: ', params);
    debugger;

    const differences = diff(currentUser, state);

    if (!differences) {
      return;
    }

    differences.forEach((difference) => {
      if (difference.kind !== 'E' || !difference.path) {
        return;
      }

      const key = difference.path.join('.');
      params[key] = difference.rhs;
    });

    // If no differences, return
    if (!Object.keys(params).length) {
      return;
    }

    // Format from object with dot notation to nested object.
    const formattedParams = deepen<Partial<User>>(params);

    const result = await axios
      .post('/api/v1/me/login', formattedParams)
      .then((response) => {
        const user = userStore.set(response.data.data);

        return {
          state: 'success',
          data: user,
        };
      })
      .catch((err) => {
        return {
          state: 'error',
          data: err,
        };
      });

    return result;
  };

  const fetchUser = async () => {
    const result = await axios
      .get(endpoint)
      .then((response) => {
        const user = userStore.set(response.data.data);

        return {
          state: 'success',
          data: user,
        };
      })
      .catch((err) => {
        return {
          state: 'error',
          data: err,
        };
      });

    return result;
  };

  const hasDeprecatedPassword = () => userStore.hasDeprecatedPassword;

  const updateDeprecatedPassword = async (data: PostDeprecatedPassword) => {
    const errors = [];

    try {
      const response = await axios.post(userPasswordEndpoint, data);

      const user = userStore.set(response.data.data);

      return {
        state: 'success',
        data: user,
      };
    } catch (error: any) {
      console.log('error: ', error);
      if (error.response.status === 422) {
        for (const key in error.response.data.errors) {
          errors.push(error.response.data.errors[key][0]);
        }

        return {
          state: 'error',
          data: errors,
        };
      }
    }
  };

  const userCanPostAsBoard = () => {
    const userStore = useUserStore();

    return userStore.canPostAsBoard;
  };

  return {
    fetchUser,
    getUser,
    getUserId,
    updateUser,
    updateUserChalet,
    updateUserLogin,
    isAdmin,
    isAuthor,
    isBoard,
    isUser,
    hasDeprecatedPassword,
    updateDeprecatedPassword,
    userCanPostAsBoard,
  };
}
