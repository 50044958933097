import '../css/app.scss';
import 'floating-vue/dist/style.css';
import './bootstrap.cjs';

import.meta.glob(['../images/**', '../fonts/**']);

import mitt from 'mitt';
import { createApp } from 'vue';

import store from '@/store';
import App from '@/views/App.vue';
import Register from '@/views/Register.vue';
import i18n from '@/i18n';
import router from '@/router';
import * as Sentry from '@sentry/vue';
import Notifications from 'notiwind';
import FloatingVue from 'floating-vue';
import { version } from '../../package.json';

/**
 * Import LanguageDropdown and set it to the global scope;
 */
// import Languages from './modules/languages';
// window.Languages = Languages;

function handleRegisterApplication() {
  const id = 'register';

  const values: any = {};
  const htmlElement = document.getElementById(id) as HTMLElement;
  const { dataset } = htmlElement;

  for (const item in dataset) {
    values[item] = JSON.parse(dataset[item] as string);
  }

  const app = createApp(Register, { ...values });

  return {
    component: app,
    id: id,
  };
}

function handleDashboardApplication() {
  const id = 'app';
  const emitter = mitt();

  const app = createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(Notifications)
    .use(FloatingVue);

  app.config.globalProperties.emitter = emitter;

  return {
    component: app,
    id: id,
  };
}

function isDebugMode() {
  return import.meta.env.VITE_APP_DEBUG;
}

function hasSentryDsn() {
  return ~import.meta.env.VITE_SENTRY_DSN;
}

/**
 * Import the vue app.
 */
document.addEventListener('DOMContentLoaded', () => {
  let app;

  if (document.getElementById('register')) {
    app = handleRegisterApplication();
  } else if (document.getElementById('app')) {
    app = handleDashboardApplication();
  }

  if (!app) {
    return;
  }

  if (hasSentryDsn() && !isDebugMode()) {
    Sentry.init({
      app: app.component,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: version,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  app.component.mount(`#${app.id}`);
});
