import Axios, { type AxiosInstance } from 'axios';
import handleServiceError from './errors/serviceErrors';
import type { IApiClient, RequestConfig } from '@/api/api.types';
import type ApiConfig from './config/ApiConfig';
import QueryString from 'qs';

export default class ApiClient implements IApiClient {
  private client: AxiosInstance;

  protected baseUrl: string = import.meta.env.VITE_BASE_URL;

  protected createAxiosClient(apiConfig: ApiConfig): AxiosInstance {
    return Axios.create({
      baseURL: this.baseUrl,
      responseType: 'json' as const,
      headers: {
        'Content-Type': 'application/json',
        ...(apiConfig.accessToken && {
          Authorization: `Token ${apiConfig.accessToken}`,
        }),
      },
      timeout: 10 * 1000,
    });
  }

  constructor(baseUrl: string, apiConfig: ApiConfig) {
    if (!baseUrl) throw new Error('BaseUrl is not defined');
    if (!apiConfig) throw new Error('ApiConfig is not defined');

    this.baseUrl = baseUrl;
    this.client = this.createAxiosClient(apiConfig);
  }

  async post<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: RequestConfig,
  ): Promise<TResponse> {
    try {
      const response = config
        ? await this.client.post<TResponse>(path, payload, config)
        : await this.client.post<TResponse>(path, payload);
      return response.data;
    } catch (error: any) {
      handleServiceError(error);
    }
    return {} as TResponse;
  }

  async patch<TRequest, TResponse>(
    path: string,
    payload: TRequest,
  ): Promise<TResponse> {
    try {
      const response = await this.client.patch<TResponse>(path, payload);
      return response.data;
    } catch (error: any) {
      handleServiceError(error);
    }
    return {} as TResponse;
  }

  async put<TRequest, TResponse>(
    path: string,
    payload: TRequest,
  ): Promise<TResponse> {
    try {
      const response = await this.client.put<TResponse>(path, payload);
      return response.data;
    } catch (error: any) {
      handleServiceError(error);
    }
    return {} as TResponse;
  }

  async delete<TResponse>(path: string): Promise<TResponse> {
    try {
      const response = await this.client.delete<TResponse>(path);
      return response.data;
    } catch (error: any) {
      handleServiceError(error);
    }
    return {} as TResponse;
  }

  async get<TResponse>(path: string, params: any): Promise<TResponse> {
    try {
      const response = await this.client.get<TResponse>(path, {
        params: params,
        paramsSerializer: (params) => {
          return QueryString.stringify(params);
        },
      });

      return response.data;
    } catch (error: any) {
      handleServiceError(error);
    }
    return {} as TResponse;
  }
}
