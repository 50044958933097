import appConfig from '@/api/config/appConfig';
import type { IApiClient } from '@/api/api.types';
import type { Reply } from '@/types/reply.types';

export interface IReplyApiClient {
  getReplies(ids: Reply['id'][]): Promise<Reply[]>;
}

interface GetRepliesResponse {
  data: Reply[];
}

export class ReplyApiClient implements IReplyApiClient {
  apiBase: string;
  replyApiClient: IApiClient;

  constructor(replyApiClient: IApiClient) {
    this.apiBase = appConfig.apiBase;
    this.replyApiClient = replyApiClient;
  }

  async getReplies(ids: Reply['id'][]): Promise<Reply[]> {
    try {
      let endpoint;

      if (ids.length) {
        endpoint = `${this.apiBase}/replies?id=${encodeURIComponent(JSON.stringify(ids))}`;
      } else {
        endpoint = `${this.apiBase}/replies`;
      }

      const response = await this.replyApiClient.get<GetRepliesResponse>(endpoint);

      return response.data;
    } catch (exception) {
      console.error(exception);
      return [];
    }
  }
}

export default class ReplyService {
  replyApiClient: IReplyApiClient;

  constructor(replyApiClient: IReplyApiClient) {
    this.replyApiClient = replyApiClient;
  }

  async getReplies(ids: Reply['id'][] = []): Promise<Reply[]> {
    return this.replyApiClient.getReplies(ids);
  };
}