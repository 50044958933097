<template>
  <ButtonBase :style-class="variantClasses" v-bind="$props">
    <svg
      v-if="loading"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 57 57"
      width="57"
      height="57"
      stroke="#fff"
    >
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)" stroke-width="2">
          <circle cx="5" cy="50" r="5">
            <animate
              attributeName="cy"
              begin="0s"
              dur="2.2s"
              values="50;5;50;50"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="cx"
              begin="0s"
              dur="2.2s"
              values="5;27;49;5"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>

          <circle cx="27" cy="5" r="5">
            <animate
              attributeName="cy"
              begin="0s"
              dur="2.2s"
              from="5"
              to="5"
              values="5;50;50;5"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="cx"
              begin="0s"
              dur="2.2s"
              from="27"
              to="27"
              values="27;49;5;27"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>

          <circle cx="49" cy="50" r="5">
            <animate
              attributeName="cy"
              begin="0s"
              dur="2.2s"
              values="50;50;5;50"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="cx"
              from="49"
              to="49"
              begin="0s"
              dur="2.2s"
              values="49;5;27;49"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  </ButtonBase>
</template>

<script setup>
import { computed } from 'vue';
import ButtonBase from '@/components/button/ButtonBase.vue';
import {
  VARIANT_NORMAL,
  VARIANT_DANGER,
  VARIANT_SECONDARY,
  VARIANT_PRIMARY,
} from '@/types/button.js';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    required: true,
    type: String,
  },
  loading: {
    default: false,
    type: Boolean,
  },
  variant: {
    default: VARIANT_NORMAL,

    type: String,
  },
});

const variantClasses = computed(() => {
  if (props.variant === VARIANT_DANGER) {
    return 'button__danger';
  }

  if (props.variant === VARIANT_SECONDARY) {
    return '';
  }

  if (props.variant === VARIANT_PRIMARY) {
    return 'button__primary';
  }

  return 'button__normal';
});
</script>

<style lang="scss" scoped>
.button {
  &__danger {
    @apply bg-red-500 border-red-500 hover:bg-red-600;
  }

  &__primary {
    @apply bg-white text-primary border-primary hover:bg-primary-light hover:border-primary-light;
  }

  &__secondary {
    @apply bg-white text-secondary border-secondary hover:bg-secondary-light hover:border-secondary-light;
  }

  &__normal {
    @apply bg-white text-grey border-grey hover:bg-grey-light;
  }
}
</style>
