import type { Organisation } from "@/types/organisation.types";
import { organisationService } from "../api";
import useOrganisationStore from "@/store/hooks/useOrganisationStore";

export default function useOrganisations() {
  const fetchOrganisation = async (): Promise<Organisation|null> => {
    const organisation = await organisationService.getOrganisation();

    if (!organisation) {
      return null;
    }

    create(organisation);

    return organisation;
  };

  const getOrganisation = (): Organisation => {
    const organisationStore = useOrganisationStore();

    return organisationStore.organisation[0];
  };

  const create = (organisation: Organisation) => {
    const organisationStore = useOrganisationStore();

    organisationStore.create(organisation);
  };

  return {
    fetchOrganisation,
    getOrganisation,
  }
}