<template>
  <button
    :class="[fontClasses, bgClasses]"
    class="inline-block px-5 py-2 mb-2 tracking-wider border rounded-lg shadow-sm hover:shadow-lg whitespace-nowrap transform-none"
    type="default"
    :disabled="disabled"
  >
    <div class="flex flex-row items-center justify-center">
      <span>
        <slot></slot>
      </span>

      <p
        color="inherit"
        style="font-family: 'IBM Plex Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', NotoColorEmoji, 'Segoe UI Symbol', 'Android Emoji', EmojiSymbols; margin: 0px; color: inherit; -webkit-font-smoothing: antialiased; font-weight: 400; font-size: 14px; letter-spacing: 0px; line-height: 20px;"
      >{{label}}</p>
    </div>
  </button>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    disabled: {
      type: Boolean,
      default: false,
    },
    label: String,
    styleClass: String,
  });

  const fontClasses = computed(() => {
    const defaultFontClass = 'text-sm font-semibold text-white select-none';

    if (props.disabled) {
      return `${defaultFontClass} cursor-not-allowed`;
    }

    return defaultFontClass;
  });

  const bgClasses = computed(() => {
    let bgClasses = props.styleClass;

    if (props.disabled) {
      bgClasses = 'bg-gray-500 border-gray-500 hover:bg-gray-500 cursor-not-allowed'
    }

    return bgClasses;
  });
</script>