import { defineStore } from 'pinia';
import type { Chalet } from '@/types/chalet.types';

export default defineStore('chalets', {
  state: () => ({
    chalets: [] as Chalet[],
  }),

  getters: {
    all: (state) => state.chalets,
    getChaletById: (state) => (id: number) => state.chalets.find((chalet) => chalet.id === id),
    getAdminChaletsTableData: (state) => {
      return state.chalets.map((chalet: Chalet) => {
        return {
          name: chalet.label,
          users: chalet.users,
          active: chalet.active,
        }
      })
    },
  },

  actions: {
    add(chalet: Chalet) {
      this.chalets.push(chalet);
    },

    update(chalet: Chalet) {
      console.log('update Chalet: ', chalet);
    },

    delete(chalet: Chalet) {
      console.log('delete Chalet: ', chalet);
    },
  },
});