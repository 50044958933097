import { settingService } from "@/api";
import useSettingsStore from "@/store/hooks/useSettingsStore";
import type { Settings } from "@/types/settings.types";

export default function useSettings() {
  const fetchSettings = async () => {
    const settings = await settingService.getSettings();

    if (!settings) {
      return null;
    }

    create(settings as Settings);

    return settings;
  };

  const getSettings = () => {
    const settingsStore = useSettingsStore();

    return settingsStore.settings;
  };

  const create = (settings: Settings) => {
    const settingsStore = useSettingsStore();

    settingsStore.create(settings);
  };

  return {
    fetchSettings,
    getSettings,
  }
}