import appConfig from '@/api/config/appConfig';
import type { IApiClient, PaginateResponse } from '@/api/api.types';
import type { Post } from '@/types/post.types';

export type GetParams = {
  page?: number;
  perPage?: number;
  channel_id?: string;
}

export interface IPostApiClient {
  getPosts(params?: GetParams): Promise<PaginateResponse<Post[]>>;
}

export class PostApiClient implements IPostApiClient {
  apiBase: string;
  postApiClient: IApiClient;

  constructor(postApiClient: IApiClient) {
    this.apiBase = appConfig.apiBase;
    this.postApiClient = postApiClient;
  }

  async getPosts(params?: GetParams): Promise<PaginateResponse<Post[]>> {
    try {
      const endpoint = `${this.apiBase}/posts`;

      const response = await this.postApiClient.get<PaginateResponse<Post[]>>(endpoint, params);

      return response;
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to fetch posts');
    }
  }
}

export default class PostService {
  postApiClient: IPostApiClient;

  constructor(postApiClient: IPostApiClient) {
    this.postApiClient = postApiClient;
  }

  async getPosts(params?: GetParams): Promise<PaginateResponse<Post[]>> {
    return this.postApiClient.getPosts(params);
  };
}