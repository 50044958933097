<template>
  <div v-if="isSubmitting">
    <Spacer size="md" />

    <div class="flex items-center justify-center">
      <div style="border-top-color:transparent" class="w-16 h-16 border-4 border-red-500 border-solid rounded-full animate-spin"></div>
    </div>

    <Spacer size="md" />

    <div class="mx-0 mt-1 text-center">
      <p class="m-0 text-gray-800 font-normal text-sm tracking-normal leading-5">{{ loadingScreen.description }}</p>
    </div>

    <Spacer size="xl" />

    <ModalFooter>
      <button-outline label="Annuleren" @click.prevent="handleClose"></button-outline>
    </ModalFooter>
  </div>

  <div v-else>
    <div class="mx-0 mb-6">
      <p class="m-0 mb-3 text-gray-800 font-normal text-sm tracking-normal leading-5">{{ text.title }}</p>

      <p class="m-0 text-gray-800 font-normal text-sm tracking-normal leading-5">{{ text.description }}</p>
    </div>

    <form>
      <PasswordInput
        label="Wachtwoord"
        name="new_password"
        placeholder="Wachtwoord"
        v-model="state.newPassword"
      />

      <div class="my-3">
        <span v-if="v$.newPassword.$error">{{ v$.newPassword.$errors[0].$message }}</span>
      </div>

      <Spacer size="xs" />

      <PasswordInput
        label="Bevestig het wachtwoord"
        name="new_password_confirmation"
        placeholder="Bevestig het wachtwoord"
        v-model="state.confirmPassword"
      />

      <div class="my-3">
        <span v-if="v$.confirmPassword.$error">{{ v$.confirmPassword.$errors[0].$message }}</span>
      </div>
    </form>

    <Spacer size="xl" />

    <ModalFooter>
      <ButtonSolid
        variant="danger"
        :disabled="v$.error"
        label="Bevestigen"
        @click.prevent="handleSubmit"
      />
    </ModalFooter>
  </div>
</template>

<script setup>
import { computed, ref, reactive } from 'vue';
import {required, sameAs, minLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import ModalFooter from '@/components/modal/ModalFooter.vue';
import ButtonOutline from '@/components/button/ButtonOutline.vue';
import Spacer from '@/components/atoms/Spacer.vue';
import ButtonSolid from '@/components/button/ButtonSolid.vue';
import PasswordInput from '@/components/atoms/input/PasswordInput.vue';
import useUsers from '@/composables/useUsers';

const emit = defineEmits(['close', 'submit'])

const { updateDeprecatedPassword } = useUsers();
const isSubmitting = ref(false);

const loadingScreen = reactive({
  description: 'Het wachtwoord wordt aangepast!',
});

const text = reactive({
  title: 'Het wachtwoord waar u mee bent ingelogd moet wegens beveiligingsredenen vernieuwd worden.',
  description: 'Gebruik het huidige of een maak nieuw wachtwoord aan, beide opties is mogelijk.',
});

const state = reactive({
  newPassword: '',
  confirmPassword: '',
});

const rules = computed(() => {
  return {
    newPassword: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      required,
      sameAs: sameAs(state.newPassword),
    },
  }
});

const v$ = useVuelidate(rules, state);

const submitUpdatePassword = async () => {
  const result = await updateDeprecatedPassword(state);

  return result.state === 'success';
};

const handleClose = () => emit('close');

const handleSubmit = async () => {
  await v$.value.$validate();

  if (v$.value.$error) {
    return;
  }

  const actions = [
    Promise.resolve(submitUpdatePassword()),
    new Promise((resolve) => setTimeout(resolve, 2000, 'Finished')),
  ];

  isSubmitting.value = true;

  Promise.all(actions).then((result) => {
    if (!result[0]) {
      isSubmitting.value = false;
      return;
    };

    emit('submit');
  })
};
</script>
