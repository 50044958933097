import appConfig from '@/api/config/appConfig';
import type { IApiClient } from '@/api/api.types';
import type { EventResponse } from '@/types/events.types';

export interface IEventsApiClient {
  getEvents(): Promise<EventResponse<string[]>>;
  sendEvent(key: string): Promise<EventResponse<string[]>>;
}

export class AdminEventsApiClient implements IEventsApiClient {
  apiBase: string;
  AdminEventsApiClient: IApiClient;

  constructor(AdminEventsApiClient: IApiClient) {
    this.apiBase = appConfig.apiBase;
    this.AdminEventsApiClient = AdminEventsApiClient;
  }

  async getEvents(): Promise<EventResponse<string[]>> {
    try {
      return this.AdminEventsApiClient.get<EventResponse<string[]>>(`${this.apiBase}/admin/events`);
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to the events');
    }
  }

  async sendEvent(key: string): Promise<any> {
    try {
      return this.AdminEventsApiClient.post(`${this.apiBase}/admin/events`, {
        event: key,
      });
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to post an event');
    }
  }
}

export default class AdminEventService {
  AdminEventsApiClient: IEventsApiClient;

  constructor(AdminEventsApiClient: IEventsApiClient) {
    this.AdminEventsApiClient = AdminEventsApiClient;
  }

  async getEvents(): Promise<EventResponse<string[]>> {
    return this.AdminEventsApiClient.getEvents();
  };

  async sendEvent(key: string): Promise<EventResponse<string[]>> {
    return this.AdminEventsApiClient.sendEvent(key);
  };
}