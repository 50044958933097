export default {
  actions: {
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    create: 'Create',
    submit: 'Submit',
  },
  app: {
    name: 'Spreeuwzwerm',
    version: 'Version {version}',
  },
  general: {
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
  },
  board: {
    name: {
      fullName: 'Board Spreeuwzwerm',
      initials: 'BSP',
    },
    post: {
      title: 'Board Spreeuwzwerm',
    },
  },
  channel: {
    createPost: {
      validation: {
        channelId: {
          required: 'Define a channel',
        },
        content: {
          required: 'The post is not optional',
        },
      },
      placeholder: 'Write a new post',
      tabs: {
        message: 'Message',
        media: 'Media',
        link: 'Link',
        poll: 'Poll',
      },
    },
    notFound: {
      description:
        'The channel you are looking for does not exist. Please contact the board if you think this is an error.',
      overview: 'Overview of all channels',
      title: 'Channel not found',
    },
  },
  channels: {
    description: 'Description',
    header: {
      name: 'Discover Channels',
      slug: 'discover-channels',
    },
    navigation: {
      messages: 'Messages',
      settings: 'Settings',
    },
    forms: {
      create: {
        field: {
          name: {
            label: 'Name for channel',
            placeholder: 'Keep it short and descriptive',
          },
          description: {
            label: 'Description',
            placeholder: 'Give a description for this channel',
          },
          posted_as_board: {
            label: 'Posted as board',
          },
          private: {
            label: 'Private channel',
          },
        },
      },
      update: {
        field: {
          name: {
            label: 'Name for channel',
            placeholder: 'Keep it short and descriptive',
          },
          description: {
            label: 'Description',
            placeholder: 'Give a description for this channel',
          },
          posted_as_board: {
            label: 'Posted as board',
          },
          private: {
            label: 'Private channel',
          },
        },
      },
    },
    page: {
      settings: {
        base: {
          title: 'Channel settings',
        },
        activate: {
          actions: {
            activate: 'Activate',
          },
          alreadyArchived: 'This channel is already archived.',
          loading: 'The channel is activated',
          title: 'Activate channel',
        },
        archive: {
          actions: {
            archive: 'Archive',
          },
          description:
            'A channel can only be archived when there are no messages present, posted by other users.',
          noArchive: 'This channel can not be archived.',
          loading: 'The channel is archived',
          title: 'Archive channel',
        },
      },
    },
    createdBy: {
      label: 'Created by',
    },
  },
  map: {
    index: {
      title: 'Map Loodsmansduin',
      description:
        'The map of Loodsmansduin is a map of the park with all the houses and their house numbers.',
      cta: 'Click on a house number and view the information provided.',
    },
    overview: {
      noUsers: 'This chalet does not contain members of the association.',
    },
  },
  menu: {
    admin: 'Admin',
    channels: 'Channels',
    map: 'Map',
    messages: 'Messages',
    settings: 'Settings',
  },
  navigation: {
    goBack: 'Go back',
    logout: 'Log out',
    panelClose: 'Close the navigation panel',
    poweredBy: 'Deliverd by MK Webdesign',
    privacy: 'Privacybeleid',
    requestHelp: 'Request Help',
    userGuideReset: 'User guide reset',
    userSettings: 'User Settings',
  },
  noResults: {
    foundNothing: 'No results found',
    endOfPosts: 'You have reach the end of the posts',
  },
  post: {
    actions: {
      delete: 'Delete',
      edit: 'Edit',
      multipleChannels: 'Duplicate to multiple channels',
      saveDraft: 'Save concept',
      show: 'Show post',
    },
    content: {
      edited: 'Updated',
    },
    delete: {
      title: 'Delete post',
      description: 'Are you sure the post should be deleted?',
      loading: 'The post is deleted',
    },
    comment: {
      label: 'Reply',
      submit: 'Reply submit',
    },
    editor: {
      create: {
        title: 'New post',
      },
      edit: {
        title: 'Edit the post',
      },
    },
    form: {
      field: {
        channel: {
          label: 'Choose your channel',
          placeholder: 'Choose your channel',
        },
        postAsBoard: {
          label: 'Post as board',
        },
      },
      submit: {
        error: 'Something went wrong while submitting this post.',
        success: 'The post has been successfully created',
      },
    },
  },
  permissions: {
    title: 'Oops, something went wrong',
    description: 'You do not have the correct permissions to view this page.',
    contact: 'Please contact the board.',
  },
  replies: 'reply|replies',
  reply: {
    actions: {
      delete: 'Delete',
      edit: 'Edit',
    },
    content: {
      edited: 'Updated',
    },
    delete: {
      title: 'Delete post',
      description: 'Are you sure the post should be deleted?',
      loading: 'The post is deleted',
    },
    answer: 'answer|answers',
    showAll: 'Show More Comments',
  },
  table: {
    channels: {
      actions: {
        merge: 'Merge Channels',
      },
      update: {
        modal: {
          title: 'Edit channel',
        },
      },
    },
    actions: {
      activate: 'Activate',
      deactivate: 'Deactivate',
      duplicate: 'Duplicate',
      edit: 'Edit',
      merge: 'Merge',
    },
  },
  user: {
    account: {
      email: 'Email address',
      label: 'My account',
      password: 'Password',
    },
    actions: {
      edit: 'Edit',
    },
    chalet: {
      chalet: 'Chalet',
      label: 'My Chalet',
      noResult: 'No chalet found.',
    },
    information: {
      additional: {
        emergencyResponse: 'Emergency Response',
      },
      modal: {
        personal: {
          address: {
            city: 'Residence',
            country: 'Country',
            number: 'Number',
            postcode: 'Postcode',
            street: 'Street',
            suffix: 'Suffix',
          },
          emergencyResponse: 'Emergency Response',
          extra: 'Extra',
          firstName: 'First name',
          language: 'Language',
          lastname: 'Lastname',
          lastnamePrefix: 'Lastame prefix',
          loading: {
            title: 'Data is being stored',
          },
          mobile: 'Mobile',
          name: 'Name',
          preferredLanguage: 'Preferred language',
          profession: 'Profession',
          title: 'Edit personal information',
          telephone: 'Telephone',
          notification: {
            error:
              'Something went wrong while saving the data. Please try again.',
            noChanges: 'No changes have been made.',
            success: 'The data has been successfully saved!',
          },
        },
        chalet: {
          chalet: 'Chalet',
          noChalet: 'No chalet',
          loading: {
            title: 'Data is being stored',
          },
          title: 'Edit chalet information',
          notification: {
            error:
              'Something went wrong while saving the chalet. Please try again.',
            noChanges: 'No changes have been made.',
            success: 'The chalet has been successfully saved!',
          },
        },
      },
      label: 'My Personal information',
      name: 'Name',
      address: 'Address',
      telephone: 'Telephone',
      mobile: 'Mobile',
      language: 'Language',
      profession: 'Profession',
    },
    invite: {
      modal: {
        description:
          'This will be link to the platform. It can be communicated differently. Be Aware!! This link is only be used once for new users. It will be deleted when the user logs in for the first time.',
      },
    },
    membership: {
      label: 'My membership',
      memberSince: 'Member since',
      role: 'Role',
    },
    photo: {
      label: 'My photo',
    },
    roles: {
      admin: 'Admin',
      board: 'Board',
      member: 'Member',
      user: 'Member',
    },
    status: {
      activate_token_requested: 'Activate token requested',
      active: 'Active',
      deprecated_password_active: 'Deprecated password still active',
      email_address_not_verified: 'Email address not verified',
      inactive: 'Inactive',
      protected: 'Protected',
      reset_password_token_requested: 'Reset password token requested',
      undefined: 'Unknown',
    },
  },
  admin: {
    users: {
      modal: {
        createUser: {
          title: 'Create a new user',
          submit: {
            error:
              'Something went wrong while creating the account. Please try again.',
            success: 'The account has been successfully created!',
          },
        },
      },
      profile: {
        modal: {
          activateAccount: {
            description: 'Are you sure the account needs to be activated?',
            loading: 'The account is activated!',
            title: 'Activate Account: {name}',
            submit: {
              error:
                'Something went wrong while activating the account. Please try again.',
              success: 'The user is invited!',
            },
          },
          deactivateAccount: {
            description:
              'Are you sure you want to deactivate this account? The user will not be able to log in anymore.',
            loading: 'The account is deactivated',
            title: 'Deactivate account: {name}',
          },
          updateAddress: {
            loading: 'The address is updated!',
            form: {
              street: 'Street',
              number: 'Number',
              suffix: 'Suffix',
              postcode: 'Postcode',
              city: 'City',
              country: 'Country',
            },
            title: 'Update address',
          },
          updateChalet: {
            loading: 'The chalet number is updated!',
            form: {
              chalet: 'Chalet',
              noChalet: 'No chalet',
            },
            title: 'Update chalet information',
          },
          updateRole: {
            loading: 'The role is updated!',
            form: {
              role: 'Role',
            },
            title: 'Update role',
          },
          removeAccount: {
            description: 'Are you sure the account needs to be removed?',
            loading: 'The account is being removed!',
            title: 'Remove Account: {name}',
            submit: {
              error:
                'Something went wrong while removing the account. Please try again.',
              success: 'The user is removed!',
            },
          },
          resetPassword: {
            title: 'Reset password',
          },
          retryResetPassword: {
            title: 'Retry reset password',
          },
        },
        actions: {
          activateAccount: 'Activate account',
          deactivateAccount: 'Deactivate account',
          removeAccount: 'Remove account',
          resetPassword: 'Reset password',
          retryResetPassword: 'Retry reset password',
          updateAddress: 'Update address',
          updateChalet: 'Update chalet information',
          updateRole: 'Update role',
        },
        activateToken: {
          label: 'Activate token',
          link: 'Link',
          sendAt: 'Send at',
          token: 'Token',
        },
        items: {
          chalet: 'Chalet',
          email: 'Email',
          map: 'Map',
          position: 'Position within the association',
          state: 'State',
          stateEvents: 'State Particularities',
        },
        headers: {
          account: 'Account',
          additional: 'Additional information',
          address: 'Address',
        },
        notification: {
          inactive:
            'This account is inactive. The user can not log in to the platform.',
          userNotFound: 'The user is not found.',
          userRemoved: 'The user is removed.',
        },
      },
    },
  },
};
