import roles from '@/helpers/roles';

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard.index',
    component: () => import('@/views/DashboardIndex.vue'),
  },
  {
    path: '/pages',
    redirect: {
      name: 'dashboard.index',
    },
  },
  {
    path: '/pages/:id',
    name: 'pages.show',
    component: () => import('@/views/pages/PagesShow.vue'),
    props: true,
    meta: {
      roles: [roles.Admin],
    },
  },
  {
    path: '/pages/create',
    name: 'pages.create',
    component: () => import('@/views/pages/PagesCreate.vue'),
    meta: {
      roles: [roles.User, roles.Admin, roles.Board],
    },
  },
  {
    path: '/channels/:id/create',
    name: 'channels.create',
    component: () => import('@/views/posts/PostCreate.vue'),
    props: true,
    meta: {
      roles: [roles.User, roles.Admin, roles.Board],
    },
  },
  {
    path: '/channels/:id/post/:postUuid/edit',
    name: 'channels.post.edit',
    component: () => import('@/views/posts/PostEdit.vue'),
    props: true,
    meta: {
      roles: [roles.User, roles.Admin, roles.Board],
    },
  },
  {
    path: '/channels/:id/post/:postUuid',
    name: 'channels.post.show',
    component: () => import('@/views/posts/PostShow.vue'),
    props: true,
    meta: {
      roles: [roles.User, roles.Admin, roles.Board],
    },
  },
  {
    path: '/channels/:id',
    name: 'channels.show',
    component: () => import('@/views/channels/ChannelsShow.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'channels.posts',
        component: () => import('@/components/templates/ChannelPosts.vue'),
        props: true,
        meta: {
          roles: [roles.User, roles.Admin, roles.Board],
        },
      },
      {
        path: 'settings',
        name: 'channels.settings',
        component: () => import('@/components/templates/ChannelSettings.vue'),
        props: true,
        meta: {
          roles: [roles.User, roles.Admin, roles.Board],
        },
      },
    ],
  },
  {
    path: '/channels',
    name: 'channels.index',
    component: () => import('@/views/channels/ChannelsIndex.vue'),
  },
  {
    path: '/posts/create',
    name: 'posts.create',
    component: () => import('@/views/posts/PostCreate.vue'),
    meta: {
      roles: [roles.User, roles.Admin, roles.Board],
    },
  },
  {
    path: '/admin',
    children: [
      {
        path: '',
        name: 'admin.index',
        component: () => import('@/views/admin/AdminIndex.vue'),
        meta: {
          roles: [roles.Admin, roles.Board],
        },
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            name: 'admin.users',
            component: () => import('@/views/admin/AdminUsers.vue'),
            props: true,
            meta: {
              roles: [roles.Admin, roles.Board],
            },
          },
          {
            path: 'detail/:id',
            name: 'admin.users.detail',
            component: () => import('@/views/admin/AdminUsersDetail.vue'),
            props: true,
            meta: {
              roles: [roles.Admin, roles.Board],
            },
          },
        ],
      },
      {
        path: 'chalets',
        children: [
          {
            path: '',
            name: 'admin.chalets',
            component: () => import('@/views/admin/AdminChalets.vue'),
            props: true,
            meta: {
              roles: [roles.Admin, roles.Board],
            },
          },
        ],
      },
      {
        path: 'channels',
        children: [
          {
            path: '',
            name: 'admin.channels',
            component: () => import('@/views/admin/AdminChannels.vue'),
            props: true,
            meta: {
              roles: [roles.Admin, roles.Board],
            },
          },
        ],
      },
      {
        path: '/migration',
        name: 'admin.migration',
        component: () => import('@/views/admin/AdminMigration.vue'),
        props: true,
        meta: {
          roles: [roles.Admin],
        },
      },
      {
        path: '/notifications',
        name: 'admin.notifications',
        component: () => import('@/views/admin/AdminNotifications.vue'),
        props: true,
        meta: {
          roles: [roles.Admin],
        },
      },
      {
        path: '/tags',
        name: 'admin.tags',
        component: () => import('@/views/admin/AdminTags.vue'),
        props: true,
        meta: {
          roles: [roles.Admin],
        },
      },
    ],
  },
  {
    path: '/map',
    name: 'map.index',
    component: () => import('@/views/MapIndex.vue'),
    meta: {
      roles: [roles.User, roles.Admin, roles.Board],
    },
  },
  {
    path: '/mysettings',
    name: 'user.settings',
    component: () => import('@/views/user/UserSettings.vue'),
    meta: {
      roles: [roles.User, roles.Admin, roles.Board],
    },
  },
  {
    path: '/no-permissions',
    name: 'no-permissions',
    component: () => import('@/views/permissions/NoPermissionsIndex.vue'),
  },
  {
    path: '/channel-not-found',
    name: 'channel-not-found',
    component: () => import('@/views/ChannelNotFound.vue'),
  },
  {
    path: '/404-page-not-found',
    name: '404-page-not-found',
    component: () => import('@/views/PageNotFound.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    redirect: {
      name: 'dashboard.index',
    },
  },
];

export default routes;
