type AppConfig = {
  baseUrl: string;
  accessToken: string;
  apiBase: string;
};

const appConfig: AppConfig = {
  baseUrl: import.meta.env.VITE_BASE_URL,
  accessToken: '',
  apiBase: '/api/v1',
};

export default appConfig;
