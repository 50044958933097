// export function GetAdminUsers() {
//   return request('admin/users');
// }

// export function PostAdminUsers() {
//   return request('admin/users', {
//     method: 'POST',
//     body: {},
//   });
// }

// export function PostAdminUsersActivate() {
//   return request('admin/users/activate', {
//     method: 'POST',
//     body: {},
//   });
// }

import type { User } from '@/types/user.types';
import appConfig from '@/api/config/appConfig';
import type { IApiClient } from '@/api/api.types';

export interface IAdminApiClient {
  getAdminUsers(): Promise<User | undefined>;
  postAdminUsers(userId: number, newStatus: AdminStatus): Promise<boolean>;
  postAdminUsersActivate(userId: number, newStatus: AdminStatus): Promise<boolean>;
}

type AdminStatus = 'active' | 'inactive';

// export interface GetAdminUsersResponse {};

export class AdminApiClient implements IAdminApiClient {
  apiBase: string;
  adminApiClient: IApiClient;

  constructor(adminApiClient: IApiClient) {
    this.apiBase = appConfig.apiBase;
    this.adminApiClient = adminApiClient;
  }

  async getAdminUsers(): Promise<User | undefined> {
    try {
      const endpoint = `${this.apiBase}/admin/users`;

      // const response = await this.adminApiClient.get<GetAdminUsersResponse>(
      const response = await this.adminApiClient.get<User[]>(endpoint);

      return response.length > 0 ? response[0] : undefined;
    } catch (exception) {
      console.error(exception);
    }
  }

  async postAdminUsers(
    userId: number,
    newStatus: AdminStatus
  ): Promise<boolean> {
    try {
      await this.adminApiClient.post(`${this.apiBase}/admin/users`, {
        tester: {
          status: newStatus,
        }
      });

      return true;
    } catch (exception) {
      console.error(exception);
      return false;
    }
  }

  async postAdminUsersActivate(
    userId: number,
    newStatus: AdminStatus
  ): Promise<boolean> {
    try {
      await this.adminApiClient.post(`${this.apiBase}/me/password`, {
        tester: {
          status: newStatus,
        },
      });

      return true;
    } catch (exception) {
      console.error(exception);
      return false;
    }
  }
}

export default class AdminService {
  adminApiClient: IAdminApiClient;

  constructor(adminApiClient: IAdminApiClient) {
    this.adminApiClient = adminApiClient;
  }

  async getAdminUsers(): Promise<User | undefined> {
    return this.adminApiClient.getAdminUsers();
  };

  async postAdminUsers(userId: number, newStatus: AdminStatus): Promise<boolean> {
    return this.adminApiClient.postAdminUsers(userId, newStatus);
  };

  async postAdminUsersActivate(userId: number, newStatus: AdminStatus): Promise<boolean> {
    return this.adminApiClient.postAdminUsersActivate(userId, newStatus);
  };
}