import appConfig from '@/api/config/appConfig';
import type { IApiClient } from '@/api/api.types';
import type { MigrationResponse } from '@/types/migration.types';

export interface IMigrationApiClient {
  getChalets(): Promise<MigrationResponse<string[]>>;
  getChannels(): Promise<MigrationResponse<string[]>>;
  getPosts(): Promise<MigrationResponse<string[]>>;
  getReplies(): Promise<MigrationResponse<string[]>>;
  getUsers(): Promise<MigrationResponse<string[]>>;
  syncChalets(params: PostParams): Promise<MigrationResponse<string[]>>;
  syncChannels(params: PostParams): Promise<MigrationResponse<string[]>>;
  syncPosts(params: PostParams): Promise<MigrationResponse<string[]>>;
  syncReplies(params: PostParams): Promise<MigrationResponse<string[]>>;
  syncUsers(params: PostParams): Promise<MigrationResponse<string[]>>;
  deleteChalets(): Promise<MigrationResponse<string[]>>;
  deleteChannels(): Promise<MigrationResponse<string[]>>;
  deletePosts(): Promise<MigrationResponse<string[]>>;
  deleteReplies(): Promise<MigrationResponse<string[]>>;
  deleteUsers(): Promise<MigrationResponse<string[]>>;
  linkStorage(): Promise<unknown>;
  optimizeClear(): Promise<unknown>;
}

type PostParams = {
  ids: string[];
};

export class AdminMigrationApiClient implements IMigrationApiClient {
  apiBase: string;
  adminMigrationApiClient: IApiClient;

  chaletsEndpoint: string;
  channelsEndpoint: string;
  postsEndpoint: string;
  repliesEndpoint: string;
  usersEndpoint: string;

  constructor(adminMigrationApiClient: IApiClient) {
    this.apiBase = appConfig.apiBase;
    this.adminMigrationApiClient = adminMigrationApiClient;

    this.chaletsEndpoint = `${this.apiBase}/admin/migration/chalets`;
    this.channelsEndpoint = `${this.apiBase}/admin/migration/channels`;
    this.postsEndpoint = `${this.apiBase}/admin/migration/posts`;
    this.repliesEndpoint = `${this.apiBase}/admin/migration/replies`;
    this.usersEndpoint = `${this.apiBase}/admin/migration/users`;
  }

  async getChalets(): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.get<MigrationResponse<string[]>>(
        this.chaletsEndpoint,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to fetch chalets');
    }
  }

  async getChannels(): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.get<MigrationResponse<string[]>>(
        this.channelsEndpoint,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to fetch channels');
    }
  }

  async getPosts(): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.get<MigrationResponse<string[]>>(
        this.postsEndpoint,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to fetch posts');
    }
  }

  async getReplies(): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.get<MigrationResponse<string[]>>(
        this.repliesEndpoint,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to fetch replies');
    }
  }

  async getUsers(): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.get<MigrationResponse<string[]>>(
        this.usersEndpoint,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to fetch users');
    }
  }

  async syncChalets(params: PostParams): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.post<
        PostParams,
        MigrationResponse<string[]>
      >(this.chaletsEndpoint, params);
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to sync chalets');
    }
  }

  async syncChannels(params: PostParams): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.post<
        PostParams,
        MigrationResponse<string[]>
      >(this.channelsEndpoint, params);
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to sync channels');
    }
  }

  async syncPosts(params: PostParams): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.post<
        PostParams,
        MigrationResponse<string[]>
      >(this.postsEndpoint, params);
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to sync posts');
    }
  }

  async syncReplies(params: PostParams): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.post<
        PostParams,
        MigrationResponse<string[]>
      >(this.repliesEndpoint, params);
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to sync replies');
    }
  }

  async syncUsers(params: PostParams): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.post<
        PostParams,
        MigrationResponse<string[]>
      >(this.usersEndpoint, params);
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to sync users');
    }
  }

  async deleteChalets(): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.delete<MigrationResponse<string[]>>(
        this.chaletsEndpoint,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to delete chalets');
    }
  }

  async deleteChannels(): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.delete<MigrationResponse<string[]>>(
        this.channelsEndpoint,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to delete channels');
    }
  }

  async deletePosts(): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.delete<MigrationResponse<string[]>>(
        this.postsEndpoint,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to delete posts');
    }
  }

  async deleteReplies(): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.delete<MigrationResponse<string[]>>(
        this.repliesEndpoint,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to delete replies');
    }
  }

  async deleteUsers(): Promise<MigrationResponse<string[]>> {
    try {
      return this.adminMigrationApiClient.delete<MigrationResponse<string[]>>(
        this.usersEndpoint,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to delete users');
    }
  }

  async linkStorage(): Promise<unknown> {
    try {
      return this.adminMigrationApiClient.post(
        `${this.apiBase}/admin/migration/storage-link`,
        null,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to link storage');
    }
  }

  async optimizeClear(): Promise<unknown> {
    try {
      return this.adminMigrationApiClient.post(
        `${this.apiBase}/admin/migration/optimize-clear`,
        null,
      );
    } catch (exception) {
      console.error(exception);
      throw new Error('Failed to clear cache');
    }
  }
}

export default class MigrationService {
  adminMigrationApiClient: IMigrationApiClient;

  constructor(adminMigrationApiClient: IMigrationApiClient) {
    this.adminMigrationApiClient = adminMigrationApiClient;
  }

  async getChalets(): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.getChalets();
  }

  async getChannels(): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.getChannels();
  }

  async getPosts(): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.getPosts();
  }

  async getReplies(): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.getReplies();
  }

  async getUsers(): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.getUsers();
  }

  async syncChalets(params: PostParams): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.syncChalets(params);
  }

  async syncChannels(params: PostParams): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.syncChannels(params);
  }

  async syncPosts(params: PostParams): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.syncPosts(params);
  }

  async syncReplies(params: PostParams): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.syncReplies(params);
  }

  async syncUsers(params: PostParams): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.syncUsers(params);
  }

  async deleteChalets(): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.deleteChalets();
  }

  async deleteChannels(): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.deleteChannels();
  }

  async deletePosts(): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.deletePosts();
  }

  async deleteReplies(): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.deleteReplies();
  }

  async deleteUsers(): Promise<MigrationResponse<string[]>> {
    return this.adminMigrationApiClient.deleteUsers();
  }

  async linkStorage(): Promise<unknown> {
    return this.adminMigrationApiClient.linkStorage();
  }

  async optimizeClear(): Promise<unknown> {
    return this.adminMigrationApiClient.optimizeClear();
  }
}
