import useConfigStore from '@/store/hooks/useConfigStore';

export default function useConfig() {
  const configStore = useConfigStore();

  const hasAdminPage = (): boolean => {
    return configStore.pages.admin;
  };

  const hasChannelsPage = (): boolean => {
    return configStore.pages.channels;
  };

  const hasDashboardPage = (): boolean => {
    return configStore.pages.dashboard;
  };

  const hasDiscoverPage = (): boolean => {
    return configStore.pages.discover;
  };

  const hasNotificationsPage = (): boolean => {
    return configStore.pages.notifications;
  };

  const hasPeoplePage = (): boolean => {
    return configStore.pages.people;
  };

  const hasPrivacyPage = (): boolean => {
    return configStore.pages.privacy;
  };

  const hasProfilePage = (): boolean => {
    return configStore.pages.profile;
  };

  const hasTimelinePage = (): boolean => {
    return configStore.pages.timeline;
  };

  const hasSettingsPage = (): boolean => {
    return true;
  };

  const getPermissions = (): unknown[] => {
    return configStore.permissions;
  };

  const hasSidebarOptions = (): boolean => {
    return configStore.sidebar.options;
  };

  const hasSidebarSort = (): boolean => {
    return configStore.sidebar.sort;
  };

  const changeDeprecatedPassword = (): boolean => {
    return configStore.events.changeDeprecatedPassword;
  };

  const hasMapPage = (): boolean => {
    return configStore.pages.maps;
  };

  const hasPermissions = (): void => {
    configStore.showPages(true);
    configStore.showNavigationItems(true);
    return;
  };

  const hasNoPermissions = (): void => {
    console.log('hasNoPermissions');
    configStore.showPages(false);
    configStore.showNavigationItems(false);
    return;
  };

  return {
    changeDeprecatedPassword,
    getPermissions,
    hasAdminPage,
    hasChannelsPage,
    hasDashboardPage,
    hasDiscoverPage,
    hasMapPage,
    hasNoPermissions,
    hasNotificationsPage,
    hasPeoplePage,
    hasPermissions,
    hasPrivacyPage,
    hasProfilePage,
    hasSettingsPage,
    hasSidebarOptions,
    hasSidebarSort,
    hasTimelinePage,
  };
}
