import useUserStore from '@/store/hooks/useUserStore';
import useUsers from '@/composables/useUsers';
import roles from '@/helpers/roles';
import { capitalizeFirstLetter } from '@/helpers/capitalize';

/**
 * Two authentication items are checked before the route change is approved:
 * - The user has ACL permission to access the route (meta.permissions)
 * - The user satisfies the canGo predicates (meta.canGo)
 * Otherwise, a redirect to dashboard is done.
 */
const validateRoles = async (to, from, next) => {
  const userStore = useUserStore();
  const { fetchUser } = useUsers();
  let user = userStore.getuser;

  if (!Object.keys(user).length) {
    const newUser = await fetchUser();

    user = newUser.data;
  }

  if (to.meta.roles) {
    const capitalizedUserRole = capitalizeFirstLetter(user.roles.role);
    const userRoleId = roles[capitalizedUserRole];

    if (!userRoleId) {
      throw new Error('The given role could not be found in the roles list.');
    }

    // Check if the route includes the role of the user.
    if (to.meta.roles.includes(userRoleId)) {
      next();
    } else {
      next('/dashboard');
    }
  } else {
    next();
  }
};

export default validateRoles;
