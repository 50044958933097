import appConfig from '@/api/config/appConfig';
import type { IApiClient } from '@/api/api.types';
import type { Organisation } from '@/types/organisation.types';

export interface IOrganisationApiClient {
  getOrganisation(): Promise<Organisation | []>;
}

export interface GetOrganisationResponse {
  data: unknown[] | [];
}

export class OrganisationApiClient implements IOrganisationApiClient {
  apiBase: string;
  organisationApiClient: IApiClient;

  constructor(organisationApiClient: IApiClient) {
    this.apiBase = appConfig.apiBase;
    this.organisationApiClient = organisationApiClient;
  }

  async getOrganisation(): Promise<Organisation | []> {
    try {
      const response =
        await this.organisationApiClient.get<GetOrganisationResponse>(
          `${this.apiBase}/organisation`,
        );

      return response.data.length > 0 ? response.data : [];
    } catch (exception) {
      console.error(exception);
      return [];
    }
  }
}

export default class OrganisationService {
  organisationApiClient: IOrganisationApiClient;

  constructor(organisationApiClient: IOrganisationApiClient) {
    this.organisationApiClient = organisationApiClient;
  }

  async getOrganisation(): Promise<Organisation | []> {
    return this.organisationApiClient.getOrganisation();
  }
}
