import type { Settings } from '@/types/settings.types';
import { defineStore } from 'pinia';

type SettingsStore = {
  settings: Settings|Object;
}

export default defineStore('settings', {
  state: (): SettingsStore => {
    return {
      settings: {},
    };
  },

  actions: {
    create(settings: Settings) {
      this.settings = settings;
    }
  },
});