<template>
  <template v-if="!loading">
    <slot />
  </template>
</template>

<script setup>
  import {onMounted, ref} from 'vue';
  import useChalets from '@/composables/useChalets';
  import useChannels from '@/composables/useChannels';
  import useOrganisations from '@/composables/useOrganisations';
  import useSettings from '@/composables/useSettings';
  import useUsers from '@/composables/useUsers';

  const { fetchChalets } = useChalets();
  const { fetchChannels } = useChannels();
  const { fetchOrganisation } = useOrganisations();
  const { fetchSettings } = useSettings();
  const { fetchUser } = useUsers();
  const loading = ref(true);

  const chalets = Promise.resolve(fetchChalets());
  const channels = Promise.resolve(fetchChannels());
  const organisation = Promise.resolve(fetchOrganisation());
  const settings = Promise.resolve(fetchSettings());
  const user = Promise.resolve(fetchUser());

  const initStores = () => {
    return Promise.all([
      chalets,
      channels,
      organisation,
      settings,
      user,
    ]);
  };

  onMounted(async () => {
    await initStores();
    loading.value = false;
  });
</script>
