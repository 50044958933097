<template>
  <div :class="[classes]" class="w-4"></div>
</template>

<script setup>
  const sizeValues = {
    'xxs': 'h-2', // 8px
    'xs': 'h-3', // 12px
    'sm': 'h-4', // 16px
    'md': 'h-5', // 20px
    'lg': 'h-6', // 24px
    'xl': 'h-10', // 40px
  };

  const props = defineProps({
    size: {
      required: true,
      type: String,
    }
  });

  const classes = sizeValues[props.size];
</script>