import type { User } from '@/types/user.types';
import appConfig from '@/api/config/appConfig';
import type { IApiClient } from '@/api/api.types';
import type { TypeOfUserStatus } from '@/types/admin.types';

export interface IUserApiClient {
  getUser(): Promise<User | null>;
  postUserImage(userId: number, newStatus: TypeOfUserStatus): Promise<boolean>;
  postUserPassword(
    userId: number,
    newStatus: TypeOfUserStatus,
  ): Promise<boolean>;
}

export interface GetUserResponse {
  data: User | null;
}

export class UserApiClient implements IUserApiClient {
  apiBase: string;
  userApiClient: IApiClient;

  constructor(userApiClient: IApiClient) {
    this.apiBase = appConfig.apiBase;
    this.userApiClient = userApiClient;
  }

  async getUser(): Promise<User | null> {
    try {
      const response = await this.userApiClient.get<GetUserResponse>(
        `${this.apiBase}/me`,
      );

      return response.data;
    } catch (exception) {
      console.error(exception);
      return null;
    }
  }

  async postUserImage(
    userId: number,
    newStatus: TypeOfUserStatus,
  ): Promise<boolean> {
    try {
      await this.userApiClient.post(`${this.apiBase}/image`, {
        tester: {
          status: newStatus,
        },
      });

      return true;
    } catch (exception) {
      console.error(exception);
      return false;
    }
  }

  async postUserPassword(
    userId: number,
    newStatus: UserStatus,
  ): Promise<boolean> {
    try {
      await this.userApiClient.post(`${this.apiBase}/me/password`, {
        tester: {
          status: newStatus,
        },
      });

      return true;
    } catch (exception) {
      console.error(exception);
      return false;
    }
  }
}

export default class UserService {
  userApiClient: IUserApiClient;

  constructor(userApiClient: IUserApiClient) {
    this.userApiClient = userApiClient;
  }

  async getUser(): Promise<User | null> {
    return this.userApiClient.getUser();
  }

  async postUserImage(
    userId: number,
    newStatus: TypeOfUserStatus,
  ): Promise<boolean> {
    return this.userApiClient.postUserImage(userId, newStatus);
  }

  async postUserPassword(
    userId: number,
    newStatus: TypeOfUserStatus,
  ): Promise<boolean> {
    return this.userApiClient.postUserPassword(userId, newStatus);
  }
}
