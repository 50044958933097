<template>
  <div class="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-center w-full h-screen overflow-hidden opacity-100 bg-primary">
    <div class="init-loader"></div>

    <h2 class="text-xl font-semibold text-center text-white">{{ title }}</h2>
  </div>
</template>

<script setup>
  const title = 'Loading';
</script>

<style lang="scss" scoped>
  .init-loader {
    @apply w-12 h-12 mb-4 ease-linear border-4 border-t-4 border-gray-200 rounded-full;

    border-top-color: #3498db;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: circle 1.5s linear infinite;
  }

  @-webkit-keyframes circle {
      0% {
          -webkit-transform: rotate(0deg);
      }

      100% {
          -webkit-transform: rotate(360deg);
      }
  }

  @keyframes circle {
      0% {
          transform: rotate(0deg);
      }

      100% {
          transform: rotate(360deg);
      }
  }
</style>