<template>
  <slot />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import useUsers from '@/composables/useUsers';
import useConfig from '@/composables/useConfig';
import roles from '@/helpers/roles';

const { hasNoPermissions } = useConfig();
const { getUser } = useUsers();
const router = useRouter();

const user = getUser();

if (user.roles.role === roles.NoUser) {
  hasNoPermissions();

  router.push({
    name: 'no-permissions',
  });
}
</script>
