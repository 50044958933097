import { defineStore } from 'pinia';
import type { Channel } from '@/types/channel.types';

type ChannelStore = {
  channels: Channel[],
}

export default defineStore('channel', {
  state: (): ChannelStore => {
    return {
      channels: [],
    };
  },

  getters: {
    getChannels: (state) => {
      return state.channels;
    },

    getById: (state) => (id: number): Channel|undefined => {
      return state.channels.find((channel: Channel) => { 
        if (!channel) {
          return false;
        }

        return channel.hasOwnProperty('id') && channel.id === id;
      });
    },

    canPostAsBoard: (state) => {
      return (ids: number[]) => {
        let canPostAsBoard = true;

        for (const id of ids) {
          const channel = state.channels.find((channel) => channel.id === id);

          if (!channel) {
            continue;
          }

          if (!channel.permissions.canPostAsBoard) {
            canPostAsBoard = false;
            break;
          }
        }

        return canPostAsBoard;
      };
    },

    getAdminChannelsTableData: (state) => {
      return state.channels.map((channel: Channel) => {
        return {
          id: channel.id,
          name: channel.name,
          description: channel.description,
          messages: 0,
          active: channel.visibility,
          private: channel.private,
        }
      });
    },
  },

  actions: {
    create(channel: Channel): Channel[] {
      const mergedChannels = [...this.channels, channel];
      const channels = [...new Map(mergedChannels.map((channel) => [channel.id, channel])).values()];

      this.channels = channels;

      return this.channels;
    },

    update(updatedChannel: Channel): Channel[] {
      const updateChannels = this.channels.map((channel) => {
        return channel.id === updatedChannel.id ? updatedChannel : channel;
      });

      this.channels = updateChannels;

      return updateChannels;
    },

    delete(id: number) {
      this.channels = this.channels.filter((channel) => channel.id !== id);
      return this.channels;
    },
  },
});