<template>
  <h1 class="text-4xl text-primary font-semibold">{{ translations.title }}</h1>

  <p class="my-4 text-sm font-normal">{{ translations.description }}</p>

  <form>
    <div class="flex flex-wrap -mx-3 mb-4">
      <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
        <TextInput
          v-model="state.firstname"
          :label="translations.firstname"
          name="firstname"
        />
      </div>

      <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
        <TextInput
          v-model="state.lastname_prefix"
          :label="translations.lastname_prefix"
          name="lastname_prefix"
        />
      </div>

      <div class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
        <TextInput
          v-model="state.lastname"
          :label="translations.lastname"
          name="lastname"
        />
      </div>

      <div class="w-full">
        <div v-if="v$.firstname.$error" class="mt-2">
          <ValidationMessage>{{
            v$.firstname.$errors[0].$message
          }}</ValidationMessage>
        </div>

        <div v-if="v$.lastname_prefix.$error" class="mt-2">
          <ValidationMessage>{{
            v$.lastname_prefix.$errors[0].$message
          }}</ValidationMessage>
        </div>

        <div v-if="v$.lastname.$error" class="mt-2">
          <ValidationMessage>{{
            v$.lastname.$errors[0].$message
          }}</ValidationMessage>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-4">
      <div class="w-full px-3 mb-4 md:mb-0">
        <DateInput
          v-model="state.dob"
          :label="translations.dob"
          name="dob"
          min="1920-01-01"
          :max="todayString"
        />

        <div v-if="v$.dob.$error" class="mt-2">
          <ValidationMessage>{{
            v$.dob.$errors[0].$message
          }}</ValidationMessage>
        </div>
      </div>
    </div>

    <Spacer size="md" />

    <div class="flex flex-wrap -mx-3 mb-4">
      <div class="w-full md:w-3/5 px-3 mb-4 md:mb-0">
        <TextInput
          v-model="state.address_street"
          :label="translations.address_street"
          name="address_street"
        />
      </div>

      <div class="w-full md:w-1/5 px-3 mb-4 md:mb-0">
        <TextInput
          v-model="state.address_number"
          :label="translations.address_number"
          name="address_number"
        />
      </div>

      <div class="w-full md:w-1/5 px-3 mb-4 md:mb-0">
        <TextInput
          v-model="state.address_suffix"
          :label="translations.address_suffix"
          name="address_suffix"
        />
      </div>

      <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
        <TextInput
          v-model="state.address_postcode"
          :label="translations.address_postcode"
          name="address_postcode"
        />
      </div>

      <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
        <TextInput
          v-model="state.address_city"
          :label="translations.address_city"
          name="address_city"
        />
      </div>

      <div class="w-full px-3 mb-4 md:mb-0">
        <TextInput
          v-model="state.address_country"
          :label="translations.address_country"
          name="address_country"
        />
      </div>
    </div>

    <Spacer size="lg" />

    <div
      v-if="props.user.chalets.length"
      class="relative flex flex-col width-full"
    >
      <div class="my-2 mx-0 gap-10">
        <p class="input__label">{{ translations.chalets }}</p>
      </div>

      <div>
        {{ props.user.chalets[0].label }} {{ props.user.chalets[0].number }}
      </div>
    </div>

    <div v-if="state.chalet_id" class="flex flex-wrap -mx-3 mb-4">
      <div class="w-full px-3 mb-4 md:mb-0">
        <DateInput
          v-model="state.owner_at"
          :label="translations.owner_at"
          name="owner_at"
          min="2000-01-01"
          :max="todayString"
        />

        <div v-if="v$.owner_at.$error" class="mt-2">
          <ValidationMessage>{{
            v$.owner_at.$errors[0].$message
          }}</ValidationMessage>
        </div>
      </div>
    </div>

    <Spacer size="lg" />

    <div class="flex flex-wrap -mx-3 mb-2">
      <div class="w-full px-3">
        <EmailInput
          v-model="state.email"
          :label="translations.email"
          name="email"
        />

        <div v-if="v$.email.$error" class="mt-2">
          <ValidationMessage>{{
            v$.email.$errors[0].$message
          }}</ValidationMessage>
        </div>
      </div>
    </div>

    <Spacer size="xs" />

    <div class="flex flex-wrap -mx-3 mb-2">
      <div class="w-full px-3">
        <PasswordInput
          v-model="state.password"
          :label="translations.password"
          name="password"
          placeholder="******************"
          :error="v$.password.$error"
        />

        <div v-if="v$.password.$error" class="mt-2">
          <ValidationMessage>{{
            v$.password.$errors[0].$message
          }}</ValidationMessage>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-12">
      <div class="w-full px-3">
        <PasswordInput
          v-model="state.confirmPassword"
          :label="translations.password_confirmation"
          name="confirmPassword"
          placeholder="******************"
          :error="v$.confirmPassword.$error"
        />

        <div v-if="v$.confirmPassword.$error" class="mt-2">
          <ValidationMessage>{{
            v$.confirmPassword.$errors[0].$message
          }}</ValidationMessage>
        </div>
      </div>
    </div>

    <div class="w-full">
      <ButtonSolid
        variant="primary"
        :disabled="v$.error"
        :loading="isSubmitting"
        :label="translations.submit"
        @click.prevent="handleSubmit"
      />
    </div>
  </form>
</template>

<script setup>
import { computed, reactive, ref } from 'vue';
import { required, minLength, email } from '@vuelidate/validators';
import axios from 'axios';
import ButtonSolid from '@/components/button/ButtonSolid.vue';
import EmailInput from '@/components/atoms/input/EmailInput.vue';
import DateInput from '@/components/atoms/input/DateInput.vue';
import PasswordInput from '@/components/atoms/input/PasswordInput.vue';
import Spacer from '@/components/atoms/Spacer.vue';
import TextInput from '@/components/atoms/input/TextInput.vue';
import useVuelidate from '@vuelidate/core';
import date from '@/modules/date.js';
import ValidationMessage from '@/components/atoms/form/ValidationMessage.vue';

const props = defineProps({
  translations: {
    type: Object,
    required: true,
  },
  user: {
    type: Object,
    required: true,
  },
});

const isSubmitting = ref(false);

const todayString = date().format('YYYY-MM-DD');

let chaletId = props.user.chalets.length ? props.user.chalets[0].id : null;

console.log(props.user);
console.log(chaletId);

const state = reactive({
  dob: props.user.dob,
  email: props.user.email,
  firstname: props.user.firstname ?? '',
  lastname_prefix: props.user.lastname_prefix ?? '',
  lastname: props.user.lastname ?? '',
  chalet_id: chaletId,
  owner_at: props.user.owner_at,
  organisation_id: props.user.organisation_id,
  role_id: props.user.role_id,
  password: '',
  confirmPassword: '',
  address_city: null,
  address_country: null,
  address_number: null,
  address_street: null,
  address_suffix: null,
  address_postcode: null,
});

const validations = computed(() => {
  const passwordIsSame = (password) => {
    return password === state.password;
  };

  const rules = {
    chalet_id: {
      required,
    },
    owner_at: {
      required,
    },
    dob: {
      required,
    },
    firstname: {
      required,
      minLength: minLength(3),
    },
    lastname_prefix: {
      minLength: minLength(1),
    },
    lastname: {
      required,
      minLength: minLength(3),
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      required,
      minLength: minLength(6),
      sameAsPassword: passwordIsSame,
    },
  };

  return rules;
});

const v$ = useVuelidate(validations, state);

const activateNewUser = async () => {
  const endpoint = `/api/v1/user/${props.user.activate_token}/activate`;

  return axios
    .post(endpoint, state)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return false;
    });
};

const handleSubmit = async () => {
  const valid = await v$.value.$validate();

  if (!valid) {
    return;
  }

  const actions = [
    Promise.resolve(activateNewUser()),
    new Promise((resolve) => setTimeout(resolve, 2000, 'Finished')),
  ];

  isSubmitting.value = true;

  Promise.all(actions).then((result) => {
    if (!result[0]) {
      isSubmitting.value = false;
      return;
    }

    const { redirectTo } = result[0];

    if (!redirectTo) {
      return;
    }

    // Redirect to the new page.
    window.location.href = redirectTo;
  });
};
</script>

<style lang="scss" scoped>
.input {
  &__label {
    margin: 0px;
    color: rgb(42, 52, 67);
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
  }
}
</style>
