import { defineStore } from 'pinia';
import type { User } from '@/types/user.types';

export default defineStore('user', {
  state: () => {
    return {
      user: {} as User,
    };
  },

  getters: {
    canPostAsBoard: (state) => state.user.permissions.canPostAsBoard,
    getuser: (state) => state.user,
    hasDeprecatedPassword: (state) => Boolean(state.user.deprecated.password),
    isAdmin: (state) => state.user.roles.isAdmin,
    isAuthor: (state) => (id: number) => state.user.id === id,
    isBoard: (state) => state.user.roles.isBoard,
    isUser: (state) => state.user.roles.isUser,
  },

  actions: {
    set(newUser: User) {
      this.user = newUser;

      return this.user;
    },
  }
});