<template>
  <Teleport to="#modal">
    <transition name="modal">
      <div
        v-if="modalState.show"
        id="modal-id"
        class="fixed inset-0 z-50 flex items-center justify-center bg-center bg-no-repeat bg-cover outline-none min-w-screen animated fadeIn faster focus:outline-none"
      >
        <div class="absolute inset-0 z-0 bg-black opacity-80"></div>

        <transition name="modal-animation-inner">
          <div
            v-if="modalState.show"
            ref="modal"
            role="modal"
            :class="`${cn(
              'relative flex flex-col max-h-[700px] outline-none overflow-x-hidden p-6 mx-auto my-auto bg-white rounded-lg shadow-lg overflow-y-auto',
              modalState.modalClass,
            )}`"
          >
            <div class="flex-auto">
              <div v-if="modalState.imgSrc">img</div>

              <spacer v-if="modalState.imgSrc" size="md"></spacer>

              <div
                v-if="modalState.title"
                class="m-0 text-2xl font-normal text-md"
              >
                {{ modalState.title }}
              </div>

              <spacer size="sm"></spacer>

              <Component
                :is="modalState.modalComponent"
                :options="modalState.options"
                @close="handleClose"
                @submit="handleSubmit"
                @keydown.esc="handleClose"
              />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>
</template>

<script setup>
import { ref } from 'vue';
import Spacer from '@/components/atoms/Spacer.vue';
import useModal from '@/composables/useModal';
import { onClickOutside } from '@vueuse/core';
import { cn } from '@/helpers/cn';

const { modalState, closeModal, resetModalState } = useModal();
const modal = ref();

onClickOutside(modal, () => closeModal());

const handleSubmit = (submitValues) => {
  closeModal(submitValues);
  resetModalState();
};

const handleClose = () => {
  closeModal();
  resetModalState();
};
</script>

<style scoped lang="scss">
.modal-enter-active,
.modal-leave-active {
  transition: all 0.25s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
</style>
