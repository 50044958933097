import useChaletStore from '@/store/hooks/useChaletStore';
import type { Chalet } from '@/types/chalet.types';
import axios from 'axios';

const endpoint = '/api/v1/chalets';

export default function useChalets() {
  const allChalets = () => {
    const chaletStore = useChaletStore();

    return chaletStore.all;
  };

  const fetchChalets = () => {
    const chaletStore = useChaletStore();

    return axios
      .get(endpoint)
      .then((response) => {
        if (!response.data) {
          return;
        }

        response.data.data.forEach((chalet: Chalet) => {
          chaletStore.add(chalet);
        });

        return chaletStore.all;
      })
      .catch((err) => {
        console.log(err);
        return chaletStore.all;
      });
  };

  const getChaletById = (id: number) => {
    const chaletStore = useChaletStore();

    return chaletStore.getChaletById(id);
  };

  return {
    allChalets,
    fetchChalets,
    getChaletById,
  };
}
